import React, { useState } from 'react'
import Card from '../components/Card'

// This component recieves an array of cards, a container name, an area and the selected area,
// plus the setSelected and removeCard functions as props, and returns one
// of the three areas which cards can be moved into

function TableContainer(props) {
  const [numcards, setNumcards] = useState(0)
  const [readytoflip, setReadytoflip] = useState(true)

  // Trigger flipping of card a bit into the future
  let ready = readytoflip
  if (numcards !== props.showcards) {
    setNumcards(props.showcards)
    setReadytoflip(false);
    ready = false
    setTimeout(() => {setReadytoflip(true)}, 50);
  }

  let cards = []
  for (let idx = 0; idx < props.showcards; idx++) {
    let card = props.board[idx]
    cards.push(<Card
            key={idx}
            code={card}
            dimmed={props.winningCards.length ? (props.winningCards.includes(card) ? 0 : 1) : 0}
            hidden={props.showcards <= idx}
            flipped={(props.showcards === 3 && ready) || (props.showcards > 3 && (idx < props.showcards - 1 || ready)) ? true : false}
            flippable={true}
          />
          )
  }
  return (
    <div>
      <div className="tablecenter">
        {cards}
      </div>
      <div className="pot">
        {props.handdescr ? props.handdescr : "Pot: " + props.pot}
      </div>
    </div>
  )
}

export default TableContainer
