import { randomElement } from './randomUtil';

/*
const _NOUN_ = [
];
*/

/**
 * The list of plural nouns.
 * @const
 */
const _PLURALNOUN_ = [
    'Abilities', 'Absences', 'Abundances', 'Academics',
    'Academies', 'Accents', 'Acceptances', 'Accesses', 'Accidents',
    'Accommodations', 'Accomplishments', 'Accordances', 'Accountabilities',
    'Accountants', 'Accounts', 'Accumulations', 'Accuracies', 'Accusations',
    'Accused', 'Achievements', 'Acids', 'Acquisitions', 'Acres', 'Actions',
    'Activations', 'Activists', 'Activities', 'Actors', 'Actresses', 'Acts',
    'Adaptations', 'Addictions', 'Additions', 'Addresses', 'Adjustments',
    'Administrations', 'Administrators', 'Admissions', 'Adolescents',
    'Adoptions', 'Ads', 'Adults', 'Advances', 'Advantages', 'Adventures',
    'Advertisements', 'Advertisings', 'Advice', 'Advocates', 'Affairs',
    'Affections', 'Aftermaths', 'Afternoons', 'Agencies', 'Agendas',
    'Agents', 'Ages', 'Aggressions', 'Agreements', 'Agricultures', 'Aides',
    'Alarms', 'Albums', 'Alerts', 'Aliens', 'Alignments',
    'Allegations', 'Alliances', 'Allies', 'Allocations', 'Allowances',
    'Alternatives', 'Aluminium', 'Amateurs', 'Ambassadors', 'Ambitions',
    'Ambulances', 'Amendments', 'Amounts', 'Analogies', 'Analyses',
    'Analysts', 'Ancestors', 'Anchors', 'Angels', 'Angers', 'Angles',
    'Animals', 'Animations', 'Ankles', 'Anniversaries', 'Announcements',
    'Answers', 'Anxieties', 'Apartments', 'Apologies', 'Apparatus',
    'Appeals', 'Appearances', 'Appetites', 'Apples', 'Applicants',
    'Applications', 'Appointments', 'Appreciations', 'Approaches',
    'Approvals', 'Apps', 'Aprils', 'Architects', 'Architectures',
    'Archives', 'Areas', 'Arenas', 'Arguments', 'Armies',
    'Arrangements', 'Arrays', 'Arrests', 'Arrivals', 'Arrows', 'Articles',
    'Artists', 'Arts', 'Artworks', 'Ashes', 'Aspects', 'Aspirations',
    'Assaults', 'Assemblies', 'Assertions', 'Assessments',
    'Assets', 'Assignments', 'Assistances', 'Assistants', 'Associations',
    'Assumptions', 'Assurances', 'Asylums', 'Athletes', 'Atmospheres',
    'Attachments', 'Attacks', 'Attempts', 'Attendances',
    'Attentions', 'Attitudes', 'Attorneys', 'Attractions', 'Attributes',
    'Auctions', 'Audiences', 'Audits', 'Augusts', 'Aunts', 'Authorities',
    'Authors', 'Autonomies', 'Autos', 'Autumns', 'Availabilities',
    'Averages', 'Awards', 'Awarenesses', 'Babies', 'Backdrops',
    'Backgrounds', 'Backings', 'Backs', 'Backups', 'Bacterias', 'Badges',
    'Bags', 'Bails', 'Balances', 'Ballets', 'Balloons', 'Ballots', 'Balls',
    'Bananas', 'Bands', 'Banks', 'Banners', 'Bans', 'Bargains', 'Barrels',
    'Barriers', 'Bars', 'Baseballs', 'Basements', 'Bases',
    'Basketballs', 'Baskets', 'Basses', 'Bathrooms', 'Baths', 'Bats',
    'Batteries', 'Battlefields', 'Battles', 'Bays', 'Beaches', 'Beams',
    'Beans', 'Bears', 'Beasts', 'Beats', 'Beauties', 'Bedrooms', 'Beds',
    'Beefs', 'Beers', 'Bees', 'Beginnings', 'Behalves', 'Behaviours',
    'Beings', 'Beliefs', 'Bells', 'Belts', 'Benches', 'Benchmarks', 'Bends',
    'Beneficiaries', 'Benefits', 'Bests', 'Bets', 'Betters', 'Biases',
    'Bicycles', 'Bids', 'Bikes', 'Bills', 'Bins', 'Biographies',
    'Biologies', 'Birds', 'Birthdays', 'Births', 'Biscuits', 'Bishops',
    'Bites', 'Bits', 'Blades', 'Blames', 'Blankets', 'Blanks',
    'Blasts', 'Blends', 'Blessings', 'Blocks', 'Blogs', 'Bloods', 'Blows',
    'Blues', 'Boards', 'Boats', 'Bonds',
    'Bones', 'Bonus', 'Bookings', 'Books', 'Booms', 'Boosts', 'Boots',
    'Borders', 'Bosses', 'Bottles', 'Boundaries', 'Bowls',
    'Bows', 'Boxes', 'Brains', 'Branches', 'Brands',
    'Breaches', 'Breads', 'Breakdowns', 'Breakfasts', 'Breaks',
    'Breakthroughs', 'Breathings', 'Breaths', 'Breeds',
    'Brethren', 'Bricks', 'Brides', 'Bridges', 'Broadbands', 'Broadcasters',
    'Broadcasts', 'Browsers', 'Brushes', 'Bubbles', 'Bucks',
    'Buddies', 'Budgets', 'Buffers', 'Bugs', 'Buildings', 'Bulks',
    'Bullets', 'Bunches', 'Burdens', 'Bureaucracies', 'Burials', 'Burns',
    'Buses', 'Bushes', 'Businesses', 'Businessmen', 'Butters', 'Buttons',
    'Cabinets', 'Cabins', 'Cables', 'Cafes', 'Cakes', 'Calculations',
    'Calls', 'Calms', 'Cameras', 'Campaigns', 'Campings', 'Camps', 'Campus',
    'Canals', 'Candidates', 'Candles', 'Cans', 'Canvas',
    'Capabilities', 'Capacities', 'Capitalisms', 'Capitals', 'Caps',
    'Captains', 'Captures', 'Carbons', 'Cards', 'Careers', 'Cares',
    'Cargoes', 'Carpets', 'Carriages', 'Carrots', 'Cars', 'Cartoons',
    'Cases', 'Cashes', 'Casinos', 'Castles', 'Casts',
    'Catalogues', 'Catches', 'Categories', 'Cats', 'Cattle', 'Causes',
    'Cautions', 'Caves', 'Cds', 'Ceilings', 'Celebrations', 'Celebrities',
    'Cells', 'Cemeteries', 'Centres', 'Cents', 'Centuries', 'Ceremonies',
    'Certainties', 'Certificates', 'Chains', 'Chairmen', 'Chairs',
    'Challenges', 'Chambers', 'Champions', 'Championships', 'Chances',
    'Changes', 'Channels', 'Chaos', 'Chapters', 'Characteristics',
    'Characters', 'Charges', 'Charities', 'Charms', 'Charters', 'Charts',
    'Chases', 'Chats', 'Cheats', 'Checks', 'Cheeks', 'Cheers', 'Cheeses',
    'Chefs', 'Chemicals', 'Chemistries', 'Chests', 'Chickens', 'Chiefs',
    'Childhoods', 'Chips', 'Chocolates', 'Choices', 'Choirs', 'Chunks',
    'Churches', 'Cigarettes', 'Cinemas', 'Circles', 'Circuits',
    'Circulations', 'Circumstances', 'Cities', 'Citizens', 'Citizenships',
    'Civilians', 'Civilizations', 'Claims', 'Clarities', 'Clashes',
    'Classes', 'Classics', 'Classifications', 'Classrooms', 'Clauses',
    'Clerks', 'Clicks', 'Clients', 'Cliffs', 'Climates', 'Climbs',
    'Clinics', 'Clips', 'Clocks', 'Closes', 'Closures', 'Clothes',
    'Clothings', 'Cloths', 'Clouds', 'Clubs', 'Clues', 'Clusters',
    'Coaches', 'Coalitions', 'Coals', 'Coasts', 'Coats', 'Cocktails',
    'Codes', 'Coffees', 'Coincidences', 'Coins', 'Colds', 'Collaborations',
    'Collapses', 'Colleagues', 'Collections', 'Collectors', 'Colleges',
    'Collisions', 'Colonies', 'Colours', 'Columnists', 'Columns', 'Combats',
    'Combinations', 'Comedies', 'Comforts', 'Comics', 'Commanders',
    'Commands', 'Commentaries', 'Commentators', 'Comments', 'Commerces',
    'Commercials', 'Commissioners', 'Commissions', 'Commitments',
    'Committees', 'Commodities', 'Communications', 'Communities',
    'Companies', 'Companions', 'Comparisons', 'Compassions',
    'Compensations', 'Competences', 'Competitions', 'Competitors',
    'Complaints', 'Completions', 'Complexes', 'Complexities', 'Compliances',
    'Complications', 'Components', 'Composers', 'Compositions', 'Compounds',
    'Compromises', 'Computers', 'Concentrations', 'Conceptions', 'Concepts',
    'Concerns', 'Concerts', 'Concessions', 'Conclusions', 'Concretes',
    'Conditions', 'Conducts', 'Conferences', 'Confessions', 'Confidences',
    'Configurations', 'Confirmations', 'Conflicts', 'Confrontations',
    'Confusions', 'Congregations', 'Connections', 'Consciences',
    'Consciousnesses', 'Consensus', 'Consents', 'Consequences',
    'Conservations', 'Conservatives', 'Considerations', 'Consistencies',
    'Conspiracies', 'Constituencies', 'Constitutions', 'Constraints',
    'Constructions', 'Consultants', 'Consultations', 'Consumers',
    'Consumptions', 'Contacts', 'Containers', 'Contempts', 'Contenders',
    'Contentions', 'Contents', 'Contests', 'Contexts', 'Continents',
    'Contractors', 'Contracts', 'Contradictions', 'Contraries', 'Contrasts',
    'Contributions', 'Contributors', 'Controls', 'Controversies',
    'Conveniences', 'Conventions', 'Conversations', 'Conversions',
    'Convictions', 'Cookers', 'Cookings', 'Cooks', 'Coordinations',
    'Coordinators', 'Copies', 'Coppers', 'Cops', 'Copyrights', 'Cores',
    'Corners', 'Corporations', 'Corrections', 'Correlations',
    'Correspondences', 'Correspondents', 'Corridors', 'Corruptions',
    'Costs', 'Costumes', 'Cottages', 'Cottons', 'Councillors', 'Councils',
    'Counsellings', 'Counsellors', 'Counterparts', 'Counters', 'Counties',
    'Countries', 'Countrysides', 'Counts', 'Couples', 'Coups', 'Courages',
    'Courses', 'Courtesies', 'Courts', 'Cousins', 'Coverages', 'Covers',
    'Cows', 'Cracks', 'Crafts', 'Crashes', 'Creams', 'Creations',
    'Creativities', 'Creators', 'Creatures', 'Credibilities', 'Credits',
    'Crews', 'Cries', 'Crises', 'Criteria',
    'Criticisms', 'Critics', 'Critiques', 'Crops', 'Crosses', 'Crowds',
    'Crowns', 'Cruises', 'Crystals', 'Cues', 'Cults', 'Cultures',
    'Cupboards', 'Cups', 'Cures', 'Curiosities', 'Currencies', 'Currents',
    'Curricula', 'Curtains', 'Custodies', 'Customers', 'Customs',
    'Cuts', 'Cuttings', 'Cycles', 'Dads', 'Dairies', 'Damages', 'Dams',
    'Dancers', 'Dances', 'Dancings', 'Dangers', 'Darknesses', 'Darks',
    'Databases', 'Dates', 'Daughters', 'Dawns', 'Days',
    'Deadlines', 'Dealers', 'Deals', 'Debates', 'Debris', 'Debts',
    'Debuts', 'Decades', 'Decembers', 'DecisionMakings', 'Decisions',
    'Decks', 'Declarations', 'Declines', 'Decorations', 'Decreases',
    'Dedications', 'Deeds', 'Defaults', 'Defeats', 'Defects', 'Defences',
    'Defenders', 'Deficiencies', 'Deficits', 'Definitions', 'Degrees',
    'Delays', 'Delegates', 'Delegations', 'Delights', 'Deliveries',
    'Demands', 'Democracies', 'Demons', 'Demonstrations', 'Denials',
    'Densities', 'Dentists', 'Departments', 'Departures', 'Dependences',
    'Deployments', 'Deposits', 'Depressions', 'Depths', 'Deputies',
    'Descents', 'Descriptions', 'Deserts', 'Designers', 'Designs',
    'Desires', 'Desks', 'Desktops', 'Destinations', 'Destructions',
    'Details', 'Detections', 'Detectives', 'Detentions', 'Determinations',
    'Developments', 'Devices', 'Devils', 'Diagnoses', 'Diagrams',
    'Dialogues', 'Diamonds', 'Diaries', 'Dictators', 'Dictionaries',
    'Diets', 'Differences', 'Difficulties', 'Dignities', 'Dilemmas',
    'Dimensions', 'Dinners', 'Diplomats', 'Directions', 'Directories',
    'Directors', 'Dirts', 'Disabilities', 'Disadvantages', 'Disagreements',
    'Disappointments', 'Disciplines', 'Disclosures',
    'Discounts', 'Discourses', 'Discoveries', 'Discretions',
    'Discs', 'Discussions', 'Dishes',
    'Disks', 'Dislikes', 'Dismissals', 'Disorders', 'Displays', 'Disposals',
    'Disputes', 'Disruptions', 'Distances', 'Distinctions', 'Distresses',
    'Distributions', 'Districts', 'Diversities', 'Dives', 'Divides',
    'Divisions', 'Divorces', 'Doctors', 'Doctrines', 'Documentaries',
    'Documentations', 'Documents', 'Dogs', 'Dollars', 'Domains',
    'Dominances', 'Donations', 'Donors', 'Doors', 'Doses', 'Dots', 'Doubts',
    'Downloads', 'Downtowns', 'Dozens', 'Drafts', 'Dramas', 'Drawings',
    'Dreams', 'Dresses', 'Drinks', 'Drivers', 'Drives', 'Drivings', 'Drops',
    'Droughts', 'Drums', 'Duos', 'Durations', 'Dusts', 'Duties',
    'Dvds', 'Dynamics', 'Earnings', 'Ears', 'Earthquakes', 'Earths',
    'Eases', 'Easts', 'Echoes', 'Economics', 'Economies', 'Economists',
    'Edges', 'Editions', 'Editors', 'Educations', 'Educators',
    'Effectivenesses', 'Effects', 'Efficiencies', 'Efforts', 'Eggs', 'Egos',
    'Elbows', 'Elections', 'Electricities', 'Electronics', 'Elements',
    'Elephants', 'Elites', 'Emails', 'Embarrassments', 'Embassies',
    'Emergences', 'Emergencies', 'Emissions', 'Emotions', 'Emphases',
    'Empires', 'Employees', 'Employers', 'Employments', 'Encounters',
    'Encouragements', 'Endeavours', 'Endings', 'Endorsements', 'Ends',
    'Enemies', 'Energies', 'Enforcements', 'Engagements', 'Engineerings',
    'Engineers', 'Engines', 'Enquiries', 'Enterprises', 'Entertainments',
    'Enthusiasms', 'Enthusiasts', 'Entities', 'Entrances', 'Entrepreneurs',
    'Entries', 'Envelopes', 'Environments', 'Epidemics', 'Episodes',
    'Equalities', 'Equals', 'Equations', 'Equipment', 'Equivalents', 'Eras',
    'Errors', 'Escapes', 'Essays', 'Essences', 'Establishments', 'Estates',
    'Estimates', 'Ethics', 'Euros', 'Evaluations', 'Evenings', 'Events',
    'Evidence', 'Evils', 'Evolutions', 'Examinations', 'Examples', 'Exams',
    'Excellences', 'Exceptions', 'Excesses', 'Exchanges', 'Excitements',
    'Exclusions', 'Excuses', 'Executives', 'Exercises',
    'Exhibitions', 'Exhibits', 'Exiles', 'Existences', 'Exits',
    'Expansions', 'Expectations', 'Expeditions', 'Expenditures', 'Expenses',
    'Experiences', 'Experiments', 'Expertises', 'Experts', 'Explanations',
    'Exploitations', 'Explorations', 'Explosions', 'Explosives', 'Exports',
    'Exposures', 'Expressions', 'Extensions', 'Extents', 'Extracts',
    'Extras', 'Extremes', 'Eyes', 'Fabrics', 'Faces',
    'Facilities', 'Factions', 'Factories', 'Factors', 'Facts', 'Faculties',
    'Failures', 'Fairnesses', 'Faiths', 'Falls', 'Fames', 'Families',
    'Fans', 'Fantasies', 'Fares', 'Farmers', 'Farmings', 'Farms',
    'Fashions', 'Fates', 'Fats', 'Faults', 'Favourites', 'Favours', 'Fears',
    'Feathers', 'Feats', 'Features', 'Februaries', 'Feedbacks', 'Feeds',
    'Feelings', 'Feels', 'Fees', 'Feet', 'Feminists', 'Fences',
    'Festivals', 'Fevers', 'Fibres', 'Fictions', 'Fields', 'Fightings',
    'Fights', 'Figures', 'Files', 'FilmMakers', 'Films', 'Filters',
    'Finals', 'Finances', 'Findings', 'Fines', 'Fingers', 'Finishes',
    'Firefighters', 'Fires', 'Fireworks', 'Firms', 'Firsts',
    'Fish', 'Fishings', 'Fitnesses', 'Fits', 'Fixes', 'Fixtures', 'Flags',
    'Flames', 'Flashes', 'Flats', 'Flavours', 'Flaws', 'Fleets', 'Fleshes',
    'Flexibilities', 'Flies', 'Flights', 'Floods', 'Floors', 'Flours',
    'Flowers', 'Flows', 'Fluids', 'Flus', 'Flyings', 'Foci', 'Folds',
    'Folks', 'Followings', 'Foods', 'Fools', 'Footages', 'Footballs',
    'Forces', 'Forecasts', 'Foreigners', 'Forests', 'Forks', 'Formations',
    'Formats', 'Forms', 'Formulae', 'Fortunes', 'Forums', 'Fossils',
    'Foundations', 'Founders', 'Fractions', 'Fragments', 'Frames',
    'Frameworks', 'Franchises', 'Frauds', 'Freedoms', 'Frequencies',
    'Fridays', 'Fridges', 'Friends', 'Friendships', 'Frogs', 'Fronts',
    'Fruits', 'Frustrations', 'Fuels', 'Functions', 'Fundings',
    'Fundraisings', 'Funds', 'Funerals', 'Funs', 'Furnitures', 'Furs',
    'Futures', 'Gains', 'Galleries', 'Gallons', 'Gamblings', 'Games',
    'Gamings', 'Gaps', 'Garages', 'Gardens', 'Gases', 'Gates',
    'Gatherings', 'Gazes', 'Gears', 'Genders', 'Generations', 'Genes',
    'Genius', 'Genres', 'Gentlemen', 'Geographies', 'Gestures',
    'Ghosts', 'Giants', 'Gifts', 'Gigs', 'Glances', 'Glasses', 'Glimpses',
    'Globalizations', 'Globes', 'Glories', 'Gloves', 'Goals', 'Gods',
    'Goes', 'Gold', 'Golfs', 'Goodbyes', 'Goodnesses', 'Goods',
    'Governances', 'Governments', 'Governors', 'Graces', 'Grades',
    'Graduates', 'Grains', 'Grandfathers', 'Grandmothers', 'Grandparents',
    'Grants', 'Graphics', 'Grasps', 'Grasses', 'Graves', 'Gravities',
    'Greenhouses', 'Greens', 'Greys', 'Grids', 'Griefs', 'Grins', 'Grips',
    'Groceries', 'Grounds', 'Groups', 'Growths', 'Guarantees', 'Guards',
    'Guerrillas', 'Guesses', 'Guests', 'Guidances', 'Guidelines', 'Guides',
    'Guilts', 'Guitars', 'Guts', 'Guys', 'Gyms', 'Habitats',
    'Habits', 'Hairs', 'Halls', 'Halts', 'Halves', 'Handfuls', 'Handles',
    'Handlings', 'Hands', 'Happinesses', 'Harassments', 'Harbours',
    'Hardwares', 'Harmonies', 'Harms', 'Harvests',
    'Hats', 'Hazards', 'Headaches', 'Headlines', 'Headquarters', 'Heads',
    'Healthcares', 'Healths', 'Hearings', 'Hearts', 'Heatings', 'Heats',
    'Heavens', 'Heels', 'Heights', 'Helicopters', 'Hellos', 'Hells',
    'Helmets', 'Helps', 'Herbs', 'Heritages', 'Heroes', 'Hierarchies',
    'Highlights', 'Highs', 'Highways', 'Hills', 'Hints', 'Hips', 'Hires',
    'Historians', 'Histories', 'Hits', 'Hobbies', 'Hockeys', 'Holds',
    'Holes', 'Holidays', 'Homelands', 'Homes', 'Homework', 'Honesties',
    'Honours', 'Hooks', 'Hopes', 'Horizons', 'Horns', 'Horrors', 'Horses',
    'Hospitals', 'Hosts', 'Hotels', 'Hours',
    'Households', 'Houses', 'Housings', 'Humanities', 'Humans', 'Humours',
    'Hungers', 'Huntings', 'Hunts', 'Hurricanes', 'Hurries', 'Hurts',
    'Hydrogens', 'Hypotheses', 'Ices', 'Icons', 'Ideals', 'Ideas',
    'Identifications', 'Identities', 'Ideologies', 'Ids',
    'Ignorances', 'Illusions', 'Illustrations', 'Imageries',
    'Images', 'Imaginations', 'Immigrations', 'Impacts',
    'Implementations', 'Implications', 'Importances', 'Imports',
    'Impressions', 'Imprisonments', 'Improvements', 'Inabilities',
    'Incentives', 'Inches', 'Incidences', 'Incidents', 'Inclusions',
    'Incomes', 'Increases', 'Independences', 'Indications', 'Indicators',
    'Indices', 'Indictments', 'Individuals', 'Industries', 'Inequalities',
    'Infections', 'Inflations', 'Influences', 'Information',
    'Infos', 'Infrastructures', 'Ingredients', 'Inhabitants', 'Initiatives',
    'Injections', 'Injuries', 'Injustices', 'Inks',
    'Innovations', 'Inputs', 'Inquiries', 'Insects', 'Insertions',
    'Insiders', 'Insides', 'Insights', 'Inspections', 'Inspectors',
    'Inspirations', 'Installations', 'Instances', 'Instincts', 'Institutes',
    'Institutions', 'Instructions', 'Instructors', 'Instruments', 'Insults',
    'Insurances', 'Intakes', 'Integrations', 'Integrities', 'Intellectuals',
    'Intelligences', 'Intensities', 'Intentions', 'Intents', 'Interactions',
    'Interests', 'Interfaces', 'Interferences', 'Interiors',
    'Interpretations', 'Intervals', 'Interventions', 'Interviews',
    'Introductions', 'Invasions', 'Inventions', 'Investigations',
    'Investigators', 'Investments', 'Investors', 'Invitations',
    'Involvements', 'Ironies', 'Irons', 'Islands', 'Isolations', 'Issues',
    'Items', 'Its', 'Jackets', 'Jails', 'Jams', 'Januaries', 'Jazzes',
    'Jeans', 'Jets', 'Jewelleries', 'Jobs', 'Joints', 'Jokes',
    'Journalisms', 'Journalists', 'Journals', 'Journeys', 'Joys',
    'Judgements', 'Judges', 'Juices', 'Julies', 'Jumps', 'Junctions',
    'Junes', 'Juries', 'Jurisdictions', 'Justices', 'Justifications',
    'Keyboards', 'Keys', 'Kicks', 'Kidneys', 'Kilometres',
    'Kinds', 'Kingdoms', 'Kings', 'Kisses', 'Kitchens', 'Kits', 'Knees',
    'Knives', 'Knocks', 'Knowledges', 'Labels', 'Laboratories', 'Labours',
    'Labs', 'Lacks', 'Ladders', 'Lakes', 'Lamps',
    'Landings', 'Landlords', 'Landmarks', 'Lands', 'Landscapes', 'Lanes',
    'Languages', 'Laps', 'Laptops', 'Lasers', 'Lasts', 'Latests', 'Laughs',
    'Laughters', 'Launches', 'Lawns', 'Laws', 'Lawsuits', 'Lawyers',
    'Layers', 'Layouts', 'Leaders', 'Leaderships', 'Leads', 'Leaflets',
    'Leagues', 'Leaks', 'Leaps', 'Learnings', 'Leathers',
    'Leaves', 'Lectures', 'Lefts', 'Legacies', 'Legends', 'Legislations',
    'Legislatures', 'Legs', 'Leisures', 'Lemons', 'Lengths', 'Lens',
    'Lessons', 'Letters', 'Levels', 'Liberals', 'Liberations', 'Liberties',
    'Libraries', 'Licences', 'Lies', 'Lifestyles', 'Lifetimes', 'Lifts',
    'Lightings', 'Lights', 'Likelihoods', 'Likes', 'Limbs', 'Limitations',
    'Limits', 'LineUps', 'Lines', 'Links', 'Lions', 'Lips', 'Liquids',
    'Listeners', 'Listings', 'Lists', 'Literacies', 'Literatures', 'Litres',
    'Litters', 'Livers', 'Lives', 'Livings', 'Loads', 'Loans', 'Lobbies',
    'Locals', 'Locations', 'Locks', 'Logics', 'Logos', 'Logs', 'Looks',
    'Loops', 'Lords', 'Lorries', 'Losses', 'Lotteries', 'Loves', 'Lows',
    'Loyalties', 'Luck', 'Lunches', 'Lungs', 'Luxuries', 'Lyrics',
    'Machineries', 'Machines', 'Magazines', 'Magics', 'Magistrates',
    'Magnitudes', 'Mails', 'Mainlands', 'Mainstreams', 'Maintenances',
    'Majorities', 'MakeUps', 'Makes', 'Makings', 'Malls',
    'Managements', 'Managers', 'Mandates', 'Manipulations', 'Manners',
    'Manufacturings', 'Manuscripts', 'Maps', 'Marathons',
    'Marches', 'Margins', 'Markers', 'Marketings', 'Marketplaces',
    'Markets', 'Marks', 'Marriages', 'Masks', 'Masses',
    'Masters', 'Matches', 'Materials', 'Mates', 'Mathematics', 'Maths',
    'Matters', 'Maximums', 'Mayors', 'Mays', 'Meals', 'Meanings', 'Means',
    'Meantimes', 'Measurements', 'Measures', 'Meats', 'Mechanics',
    'Mechanisms', 'Medals', 'Media', 'Medications', 'Medicines',
    'Meditations', 'Meetings', 'Melodies', 'Members', 'Memberships',
    'Memoirs', 'Memorials', 'Memories', 'Memos', 'Mentions',
    'Mentors', 'Menus', 'Merchants', 'Mercies', 'Mergers', 'Merits',
    'Messages', 'Messes', 'Metals', 'Metaphors', 'Methodologies', 'Methods',
    'Metres', 'Mice', 'Middles', 'Midnights', 'Midsts', 'Migrations',
    'Miles', 'Milks', 'Mills',
    'Minds', 'Minerals', 'Miners', 'Mines', 'Minimums', 'Minings',
    'Ministers', 'Ministries', 'Minutes', 'Miracles',
    'Mirrors', 'Miseries', 'Missiles', 'Missions', 'Mistakes', 'Mixes',
    'Mixtures', 'Mobiles', 'Mobilities', 'Mobs', 'Modes',
    'Modifications', 'Momenta', 'Moments', 'Mondays', 'Moneys', 'Monitors',
    'Monkeys', 'Monks', 'Monopolies', 'Monsters', 'Months', 'Monuments',
    'Moods', 'Moons', 'Moralities', 'Morals', 'Mornings', 'Mortgages',
    'Mothers', 'Motions', 'Motivations', 'Motives',
    'Motorcycles', 'Motorists', 'Motors', 'Mountains', 'Mouths',
    'Movements', 'Moves', 'Movies', 'Muds', 'Mums', 'Muscles',
    'Museums', 'Music', 'Musicals', 'Musicians', 'Mysteries', 'Myths',
    'Nails', 'Names', 'Narratives', 'Nationals', 'Nations',
    'Natures', 'Navigations', 'Necessities', 'Necks', 'Needles', 'Needs',
    'Negatives', 'Neglects', 'Negotiations', 'Neighbourhoods', 'Neighbours',
    'Nerves', 'Nests', 'Nets', 'Networks', 'News', 'Newsletters',
    'Newspapers', 'Niches', 'Nightmares', 'Nights', 'Noises', 'Nominations',
    'Nominees', 'Nonsenses', 'Noons', 'Normals', 'Norms', 'Norths', 'Noses',
    'Notebooks', 'Notes', 'Notices', 'Notions', 'Novelists', 'Novels',
    'Novembers', 'Numbers', 'Nurseries', 'Nurses', 'Nursings', 'Nutritions',
    'Nuts', 'Obesities', 'Objections', 'Objectives', 'Objects',
    'Obligations', 'Observations', 'Observers', 'Obsessions', 'Obstacles',
    'Occasions', 'Occupations', 'Occurrences', 'Oceans', 'Octobers', 'Odds',
    'Offences', 'Offerings', 'Offers', 'Officers', 'Offices',
    'Officials', 'Offspring', 'Oils', 'Onions', 'Openings', 'Operas',
    'Operations', 'Operators', 'Opinions', 'Opponents', 'Opportunities',
    'Opposites', 'Oppositions', 'Optimisms', 'Options', 'Oranges',
    'Orchestras', 'Orders', 'Organizations', 'Organizers', 'Organs',
    'Orientations', 'Originals', 'Origins', 'Outbreaks', 'Outcomes',
    'Outfits', 'Outings', 'Outlets', 'Outlines', 'Outlooks', 'Outputs',
    'Outrages', 'Outsiders', 'Outsides', 'Ovens', 'Owners', 'Ownerships',
    'Oxygens', 'Paces', 'Packages', 'Packets', 'Packs', 'Pads', 'Pages',
    'Pains', 'Painters', 'Paintings', 'Paints', 'Pairs', 'Palaces', 'Palms',
    'Panels', 'Panics', 'Pans', 'Pants', 'Papers', 'Parades', 'Paragraphs',
    'Parallels', 'Parameters', 'Parents', 'Parishes', 'Parkings', 'Parks',
    'Parliaments', 'Participants', 'Participations', 'Parties', 'Partners',
    'Partnerships', 'Parts', 'Passages', 'Passengers', 'Passes', 'Passings',
    'Passions', 'Passports', 'Passwords', 'Pastors', 'Pasts', 'Patches',
    'Patents', 'Paths', 'Pathways', 'Patiences', 'Patients', 'Patrols',
    'Patrons', 'Patterns', 'Pauses', 'Payments', 'Pays', 'Peaces', 'Peaks',
    'Peasants', 'Peers', 'Penalties', 'Pencils', 'Pennies', 'Pens',
    'Pensions', 'People', 'Peoples', 'Peppers', 'Percentages',
    'Perceptions', 'Performances', 'Periods', 'Permissions', 'Permits',
    'Personalities', 'Personnels', 'Perspectives', 'Petitions', 'Petrols',
    'Pets', 'Phases', 'Phenomena', 'Philosophers', 'Philosophies', 'Phones',
    'Photographers', 'Photographies', 'Photographs', 'Photos', 'Phrases',
    'Physicians', 'Physics', 'Pianos', 'Picks', 'Pictures', 'Pieces',
    'Pigs', 'Piles', 'Pills', 'Pilots', 'Pinks', 'Pins', 'Pioneers',
    'Pipelines', 'Pipes', 'Pirates', 'Pitches', 'Pities', 'Pits',
    'Placements', 'Places', 'Planes', 'Planets', 'Plannings', 'Plans',
    'Plants', 'Plastics', 'Plates', 'Platforms', 'Players', 'Plays',
    'Pleas', 'Pleasures', 'Pledges', 'Plots', 'Plugs', 'Plus', 'Pockets',
    'Poems', 'Poetries', 'Poets', 'Points', 'Poisons', 'Poles', 'Police',
    'Policemen', 'Policies', 'Politicians', 'Politics', 'Polls',
    'Pollutions', 'Ponds', 'Pools', 'Pops', 'Popularities', 'Populations',
    'Portfolios', 'Portions', 'Portraits', 'Ports', 'Positions',
    'Positives', 'Possessions', 'Possibilities', 'Posters', 'Posts',
    'Potatoes', 'Potentials', 'Pots', 'Pounds', 'Poverties', 'Powders',
    'Powers', 'Practices', 'Practitioners', 'Praises', 'Prayers',
    'Precedents', 'Precisions', 'Predators', 'Predecessors', 'Predictions',
    'Preferences', 'Pregnancies', 'Prejudices', 'Premises', 'Premiums',
    'Preparations', 'Prescriptions', 'Presences', 'Presentations',
    'Presents', 'Preservations', 'Presidencies', 'Presidents', 'Presses',
    'Pressures', 'Prevalences', 'Preventions', 'Preys', 'Prices', 'Prides',
    'Priests', 'Princes', 'Princesses', 'Principals', 'Principles',
    'Printers', 'Printings', 'Prints', 'Priorities', 'Prisons',
    'Privacies', 'Privatizations', 'Privileges', 'Prizes', 'Probabilities',
    'Probes', 'Problems', 'Procedures', 'Proceedings', 'Proceeds',
    'Processes', 'Processings', 'Processors', 'Producers', 'Produces',
    'Productions', 'Productivities', 'Products', 'Professionals',
    'Professions', 'Professors', 'Profiles', 'Profits', 'Programmes',
    'Programmings', 'Programs', 'Progresses', 'Projections', 'Projects',
    'Promises', 'Promotions', 'Proofs', 'Propagandas', 'Properties',
    'Proportions', 'Proposals', 'Propositions', 'Prosecutions',
    'Prosecutors', 'Prospects', 'Prosperities', 'Protections', 'Proteins',
    'Protesters', 'Protests', 'Protocols', 'Provinces', 'Provisions',
    'Psychologies', 'Psychologists', 'Publications', 'Publicities',
    'Publics', 'Publishings', 'Pubs', 'Pulls', 'Pulses', 'Pumps', 'Punches',
    'Punishments', 'Punks', 'Pupils', 'Purchases', 'Purples', 'Purposes',
    'Pursuits', 'Pushes', 'Puzzles', 'Qualifications', 'Qualities',
    'Quantities', 'Quarters', 'Queens', 'Queries', 'Questionnaires',
    'Questions', 'Quests', 'Queues', 'Quotas', 'Quotations', 'Quotes',
    'Races', 'Racings', 'Radars', 'Radiations',
    'Radios', 'Rages', 'Raids', 'Rails', 'Railways', 'Rains', 'Rallies',
    'Ranges', 'Rankings', 'Ranks', 'Rates', 'Ratings', 'Ratios',
    'Rats', 'Rays', 'Reaches', 'Reactions', 'Readers', 'Readings',
    'Realities', 'Realizations', 'Realms', 'Rears', 'Reasonings', 'Reasons',
    'Rebellions', 'Rebels', 'Receipts', 'Receivers', 'Receptions',
    'Recessions', 'Recipes', 'Recipients', 'Recognitions',
    'Recommendations', 'Reconstructions', 'Recordings', 'Records',
    'Recoveries', 'Recruitments', 'Recruits', 'Reductions',
    'Referees', 'References', 'Referendums', 'Reflections', 'Reforms',
    'Refusals', 'Regards', 'Regimes', 'Regions',
    'Registers', 'Registrations', 'Regrets', 'Regulations', 'Regulators',
    'Rehabilitations', 'Reigns', 'Rejections', 'Relations', 'Relationships',
    'Relatives', 'Releases', 'Relevances', 'Reliabilities', 'Reliefs',
    'Religions', 'Remainders', 'Remains', 'Remarks', 'Remedies',
    'Reminders', 'Removals', 'Rentals', 'Rents', 'Repairs', 'Repeats',
    'Replacements', 'Replies', 'Reporters', 'Reportings', 'Reports',
    'Representations', 'Representatives', 'Reproductions', 'Republics',
    'Reputations', 'Requests', 'Requirements', 'Rescues', 'Researchers',
    'Researches', 'Reservations', 'Reserves', 'Residences', 'Residents',
    'Residues', 'Resignations', 'Resistances', 'Resolutions', 'Resorts',
    'Resources', 'Respects', 'Responses', 'Responsibilities', 'Restaurants',
    'Restorations', 'Restraints', 'Restrictions', 'Rests', 'Results',
    'Retails', 'Retirements', 'Retreats', 'Returns', 'Revelations',
    'Revenges', 'Revenues', 'Reverses', 'Reviews', 'Revisions', 'Revivals',
    'Revolutions', 'Rewards', 'Rhetorics', 'Rhythms', 'Rices', 'Rides',
    'Rifles', 'Rights', 'Rings', 'Riots', 'Rises', 'Risks', 'Rituals',
    'Rivals', 'Rivers', 'Roads', 'Robberies', 'Robots', 'Rockets', 'Rocks',
    'Rods', 'Roles', 'Rolls', 'Romances', 'Roofs', 'Rooms', 'Roots',
    'Ropes', 'Roses', 'Rotations', 'Rounds', 'Routes', 'Routines', 'Rows',
    'Rubbers', 'Rubbishes', 'Rugbies', 'Ruins', 'Rules', 'Rulings',
    'Rumours', 'Runners', 'Runnings', 'Runs', 'Rushes', 'Sacrifices',
    'Safeties', 'Sailings', 'Sailors', 'Sails', 'Saints', 'Sakes', 'Salads',
    'Salaries', 'Sales', 'Salts', 'Samples', 'Sanctions', 'Sands',
    'Sandwiches', 'Satellites', 'Satisfactions', 'Saturdays', 'Sauces',
    'Savings', 'Says', 'Scales', 'Scandals', 'Scares', 'Scenarios',
    'Scenes', 'Schedules', 'Schemes', 'Scholars', 'Scholarships', 'Schools',
    'Sciences', 'Scientists', 'Scopes', 'Scores', 'Scratches', 'Screams',
    'Screenings', 'Screens', 'Screws', 'Scripts', 'Scrutinies',
    'Sculptures', 'Seals', 'Searches', 'Seas', 'Seasons', 'Seats',
    'Seconds', 'Secretaries', 'Secrets', 'Sections', 'Sectors',
    'Securities', 'Seeds', 'Seekers', 'Segments', 'Selections', 'Selves',
    'Seminars', 'Senators', 'Sensations', 'Senses', 'Sensitivities',
    'Sentences', 'Sentiments', 'Separations', 'Septembers', 'Sequences',
    'Series', 'Servants', 'Services', 'Sessions', 'SetUps', 'Sets',
    'Settings', 'Settlements', 'Settlers', 'Shades',
    'Shadows', 'Shakes', 'Shames', 'Shapes', 'Shareholders', 'Shares',
    'Sheep', 'Sheets', 'Shells', 'Shelters', 'Shelves', 'Shifts',
    'Shippings', 'Ships', 'Shirts', 'Shocks', 'Shoes', 'Shootings',
    'Shoots', 'Shoppings', 'Shops', 'Shores', 'Shortages', 'Shots',
    'Shoulders', 'Shouts', 'Showers', 'Shows', 'Siblings', 'Sides', 'Sighs',
    'Sights', 'Signals', 'Signatures', 'Significances', 'Signs', 'Silences',
    'Silks', 'Silver', 'Similarities', 'Simulations', 'Singers', 'Singings',
    'Singles', 'Sins', 'Sirs', 'Sites', 'Situations', 'Sizes',
    'Sketches', 'Skies', 'Skiings', 'Skills', 'Skins', 'Skirts', 'Skis',
    'Skulls', 'Sleeps', 'Slices', 'Slides',
    'Slogans', 'Slopes', 'Slots', 'Smartphones', 'Smells', 'Smiles',
    'Smokes', 'Smokings', 'Snakes', 'Snows', 'Soaps', 'Soccers',
    'Societies', 'Socks', 'Softwares', 'Soils', 'Soldiers', 'Solicitors',
    'Solidarities', 'Solids', 'Solos', 'Solutions', 'Songs', 'Sons',
    'Sorts', 'Souls', 'Sounds', 'Soups', 'Sources',
    'Sovereignties', 'Spaces', 'Spams', 'Spans', 'Speakers', 'Specialists',
    'Species', 'Specifications', 'Specimens', 'Spectacles', 'Spectators',
    'Spectra', 'Speculations', 'Speeches', 'Speeds', 'Spellings', 'Spells',
    'Spendings', 'Spheres', 'Spices', 'Spiders', 'Spies', 'Spines', 'Spins',
    'Spirits', 'Spites', 'Splits', 'Spokesmen', 'Spokespeople', 'Sponsors',
    'Sponsorships', 'Spoons', 'Sports', 'Spotlights', 'Spots', 'Spouses',
    'Spreads', 'Springs', 'Squads', 'Squares', 'Stabilities', 'Stadiums',
    'Staffs', 'Stages', 'Stairs', 'Stakes', 'Stalls', 'Stamps', 'Stances',
    'Standards', 'Stands', 'Stars', 'Starts', 'Statements', 'States',
    'Stations', 'Statistics', 'Statues', 'Status', 'Stays', 'Steams',
    'Steels', 'Stems', 'Steps', 'Stereotypes', 'Sticks', 'Stimuli',
    'Stocks', 'Stomachs', 'Stones', 'Stops', 'Storages', 'Stores',
    'Stories', 'Storms', 'Strains', 'Strands', 'Strangers', 'Strategies',
    'Streams', 'Streets', 'Strengths', 'Stresses', 'Stretches', 'Strikes',
    'Strings', 'Strips', 'Strokes', 'Structures', 'Struggles', 'Students',
    'Studies', 'Studios', 'Stuffs', 'Styles', 'Subjects', 'Submissions',
    'Subscribers', 'Subscriptions', 'Subsidies', 'Substances',
    'Substitutes', 'Substitutions', 'Suburbs', 'Successes', 'Successions',
    'Successors', 'Sufferings', 'Sugars', 'Suggestions',
    'Suites', 'Suits', 'Summaries', 'Summers', 'Summits', 'Sums', 'Sundays',
    'Suns', 'Supermarkets', 'Supervisions', 'Supervisors', 'Supplements',
    'Supplies', 'Supporters', 'Supports', 'Surfaces', 'Surgeons',
    'Surgeries', 'Surges', 'Surplus', 'Surprises', 'Surveillances',
    'Surveys', 'Survivals', 'Survivors', 'Suspects', 'Suspensions',
    'Suspicions', 'Sweaters', 'Sweets', 'Swimmings', 'Swims', 'Swings',
    'Switches', 'Swords', 'Symbols', 'Sympathies', 'Symptoms', 'Syndromes',
    'Syntheses', 'Systems', 'TShirts', 'Tables', 'Tablets', 'Tackles',
    'Tactics', 'Tags', 'Tails', 'Talents', 'Tales', 'Talks', 'Tanks',
    'Tapes', 'Taps', 'Targets', 'Tasks', 'Tastes', 'Taxes', 'Taxis',
    'Taxpayers', 'Teachers', 'Teachings', 'Teams', 'Tears', 'Teas',
    'Techniques', 'Technologies', 'Teenagers', 'Teens', 'Teeth',
    'Telephones', 'Televisions', 'Temperatures', 'Temples', 'Tenants',
    'Tendencies', 'Tennis', 'Tensions', 'Tents', 'Tenures', 'Terminals',
    'Terms', 'Terrains', 'Territories',
    'Testimonies', 'Testings', 'Tests', 'Textbooks', 'Texts',
    'Textures', 'Thanks', 'Theatres', 'Thefts', 'Themes', 'Theologies',
    'Theories', 'Therapies', 'Therapists', 'Theses', 'Thieves', 'Things',
    'Thinkings', 'Thirds', 'Thoughts', 'Threads', 'Threats', 'Thresholds',
    'Throats', 'Thumbs', 'Thursdays', 'Tickets', 'Tides', 'Ties', 'Timbers',
    'Times', 'Timings', 'Tins', 'Tips', 'Tissues', 'Titles', 'Tobaccos',
    'Todays', 'Toes', 'Toilets', 'Tolerances', 'Tolls', 'Tomatoes',
    'Tomorrows', 'Tones', 'Tongues', 'Tonights', 'Tonnes', 'Tons', 'Tools',
    'Topics', 'Tops', 'Tortures', 'Totals', 'Touches', 'Tourisms',
    'Tourists', 'Tournaments', 'Tours', 'Towels', 'Towers', 'Towns', 'Toys',
    'Traces', 'Tracks', 'Trademarks', 'Trades', 'Tradings', 'Traditions',
    'Traffics', 'Tragedies', 'Trailers', 'Trails', 'Trainers', 'Trainings',
    'Trains', 'Traits', 'Transactions', 'Transcripts', 'Transfers',
    'Transformations', 'Transitions', 'Transits', 'Translations',
    'Transmissions', 'Transparencies', 'Transportations', 'Transports',
    'Traps', 'Traumas', 'Travellers', 'Travels', 'Treasures', 'Treaties',
    'Treatments', 'Trees', 'Trends', 'Trials', 'Tribes', 'Tribunals',
    'Tributes', 'Tricks', 'Tries', 'Triggers', 'Trios', 'Trips', 'Triumphs',
    'Troops', 'Trophies', 'Troubles', 'Trousers', 'Trucks', 'Trustees',
    'Trusts', 'Truths', 'Tsunamis', 'Tubes', 'Tuesdays', 'Tuitions',
    'Tunes', 'Tunnels', 'Turnouts', 'Turnovers', 'Turns', 'Tvs',
    'Twists', 'Types', 'Tyres', 'Umbrellas', 'Uncertainties', 'Uncles',
    'Undergraduates', 'Understandings', 'Underwears', 'Unemployments',
    'Uniforms', 'Unions', 'Unities', 'Units', 'Universes', 'Universities',
    'Updates', 'Upgrades', 'Usages', 'Users', 'Uses', 'Utilities',
    'Vacations', 'Vacuums', 'Validities', 'Valleys', 'Values', 'Vans',
    'Variables', 'Variations', 'Varieties', 'Vegetables', 'Vehicles',
    'Veins', 'Ventures', 'Venues', 'Verdicts', 'Verses', 'Versions',
    'Vessels', 'Veterans', 'Vices', 'Victories', 'Videos',
    'Viewers', 'Viewpoints', 'Views', 'Villagers', 'Villages', 'Violations',
    'Violences', 'Virtues', 'Viruses', 'Visas', 'Visions', 'Visitors',
    'Visits', 'Vitamins', 'Voices', 'Volumes', 'Volunteers', 'Votes',
    'Votings', 'Vulnerabilities', 'Wages', 'Waiters', 'Waits', 'Walks',
    'Walls', 'Wards', 'Warehouses', 'Warfares', 'Warmings', 'Warnings',
    'Warrants', 'Warriors', 'Washes', 'Washings', 'Wastes',
    'Watches', 'Waters', 'Waves', 'Ways', 'Weaknesses', 'Wealths',
    'Weathers', 'Webs', 'Websites', 'Weddings', 'Wednesdays',
    'Weeds', 'Weekends', 'Weeks', 'Weights', 'Welcomes', 'Welfares',
    'WellBeings', 'Wells', 'Wests', 'Wheat', 'Wheels', 'Whispers',
    'Wholes', 'Widows', 'Widths', 'Wildlives', 'Willingnesses',
    'Wills', 'Windows', 'Winds', 'Wines', 'Wings', 'Winners', 'Wins',
    'Winters', 'Wires', 'Wisdoms', 'Wishes', 'Withdrawals', 'Witnesses',
    'Wits', 'Wonders', 'Woods', 'Wools', 'Words', 'Workers', 'Workforces',
    'Workouts', 'Workplaces', 'Works', 'Workshops', 'Worlds', 'Worms',
    'Worries', 'Worses', 'Worships', 'Worsts', 'Worths', 'Wounds', 'Wrists',
    'Writers', 'Writings', 'Wrongs', 'Yards', 'Years', 'Yellows',
    'Yesterdays', 'Yields', 'Zones'
];

/*
const _PLACE_ = [
    'Pub', 'University', 'Airport', 'Library', 'Mall', 'Theater', 'Stadium',
    'Office', 'Show', 'Gallows', 'Beach', 'Cemetery', 'Hospital', 'Reception',
    'Restaurant', 'Bar', 'Church', 'House', 'School', 'Square', 'Village',
    'Cinema', 'Movies', 'Party', 'Restroom', 'End', 'Jail', 'PostOffice',
    'Station', 'Circus', 'Gates', 'Entrance', 'Bridge'
];
*/

/**
 * The list of verbs.
 * @const
 */
const _VERB_ = [
    'Abolish', 'Absorb', 'Accelerate', 'Accept',
    'Access', 'Accommodate', 'Accompany', 'Accomplish', 'Account',
    'Accumulate', 'Accuse', 'Achieve', 'Acknowledge', 'Acquire', 'Act',
    'Activate', 'Adapt', 'Add', 'Address', 'Adhere', 'Adjust', 'Administer',
    'Admire', 'Admit', 'Adopt', 'Advance', 'Advertise', 'Advise',
    'Advocate', 'Affect', 'Afford', 'Age', 'Agree', 'Aid', 'Aim', 'Alarm',
    'Alert', 'Align', 'Allege', 'Allocate', 'Allow', 'Alter', 'Amend',
    'Amount', 'Analyse', 'Announce', 'Annoy', 'Answer', 'Anticipate',
    'Apologize', 'Appeal', 'Appear', 'Applaud', 'Apply', 'Appoint',
    'Appreciate', 'Approach', 'Approve', 'Argue', 'Arise', 'Arm', 'Arrange',
    'Arrest', 'Arrive', 'Articulate', 'Ask', 'Aspire',
    'Assemble', 'Assert', 'Assess', 'Assign', 'Assist', 'Associate',
    'Assume', 'Assure', 'Attach', 'Attain', 'Attempt', 'Attend',
    'Attract', 'Attribute', 'Authorize', 'Average', 'Avoid', 'Await',
    'Award', 'Back', 'Bake', 'Balance', 'Ban', 'Bar', 'Base', 'Bat',
    'Battle', 'Be', 'Bear', 'Beat', 'Become', 'Beg', 'Begin', 'Behave',
    'Believe', 'Belong', 'Bend', 'Benefit', 'Bet', 'Betray', 'Bid', 'Bill',
    'Bind', 'Bite', 'Blame', 'Blast', 'Blend', 'Bless', 'Block',
    'Blow', 'Board', 'Boast', 'Boil', 'Book', 'Boost', 'Border',
    'Borrow', 'Bother', 'Bounce', 'Bow', 'Brand', 'Breach', 'Break',
    'Breathe', 'Breed', 'Bring', 'Broadcast', 'Brush', 'Build', 'Burn',
    'Burst', 'Bury', 'Buy', 'Calculate', 'Call', 'Calm', 'Camp', 'Campaign',
    'Cancel', 'Capture', 'Care', 'Carry', 'Carve', 'Cast', 'Catch', 'Cater',
    'Cause', 'Cease', 'Celebrate', 'Centre', 'Chain', 'Chair', 'Challenge',
    'Change', 'Characterize', 'Charge', 'Chart', 'Chase', 'Chat', 'Cheat',
    'Check', 'Cheer', 'Choose', 'Chop', 'Circle', 'Circulate', 'Cite',
    'Claim', 'Clarify', 'Classify', 'Clean', 'Clear', 'Click', 'Climb',
    'Cling', 'Close', 'Coach', 'Coincide', 'Collaborate', 'Collapse',
    'Collect', 'Combat', 'Combine', 'Come', 'Comfort', 'Command',
    'Commence', 'Comment', 'Commission', 'Commit', 'Communicate', 'Compare',
    'Compel', 'Compensate', 'Compete', 'Compile', 'Complain', 'Complement',
    'Complete', 'Comply', 'Compose', 'Comprise', 'Compromise', 'Compute',
    'Conceal', 'Concede', 'Conceive', 'Concentrate', 'Concern', 'Conclude',
    'Condemn', 'Conduct', 'Confer', 'Confess', 'Confine', 'Confirm',
    'Conflict', 'Confront', 'Confuse', 'Congratulate', 'Connect', 'Conquer',
    'Consent', 'Conserve', 'Consider', 'Consist', 'Consolidate',
    'Constitute', 'Construct', 'Consult', 'Consume', 'Contact', 'Contain',
    'Contemplate', 'Contend', 'Contest', 'Continue', 'Contract', 'Contrast',
    'Contribute', 'Control', 'Convert', 'Convey', 'Convict', 'Convince',
    'Cook', 'Cool', 'Cooperate', 'Coordinate', 'Cope', 'Copy', 'Correct',
    'Correlate', 'Correspond', 'Cost', 'Count', 'Counter', 'Cover', 'Crack',
    'Craft', 'Crash', 'Crawl', 'Create', 'Credit', 'Creep', 'Criticize',
    'Cross', 'Cruise', 'Crush', 'Cry', 'Cultivate', 'Cure', 'Curve', 'Cut',
    'Cycle', 'Damage', 'Dance', 'Dare', 'Date', 'Deal', 'Debate', 'Decide',
    'Declare', 'Decline', 'Decorate', 'Decrease', 'Deem', 'Defeat',
    'Defend', 'Define', 'Defy', 'Delay', 'Delete', 'Delight', 'Deliver',
    'Demand', 'Demonstrate', 'Denounce', 'Deny', 'Depart', 'Depend',
    'Depict', 'Deploy', 'Deposit', 'Deprive', 'Derive', 'Descend',
    'Describe', 'Desert', 'Deserve', 'Design', 'Designate', 'Desire',
    'Destroy', 'Detail', 'Detain', 'Detect', 'Deteriorate', 'Determine',
    'Devastate', 'Develop', 'Devise', 'Devote', 'Diagnose', 'Dictate',
    'Differ', 'Differentiate', 'Dig', 'Diminish', 'Dip', 'Direct',
    'Disagree', 'Disappear', 'Disappoint', 'Discard', 'Discharge',
    'Disclose', 'Discount', 'Discourage', 'Discover', 'Discuss', 'Dislike',
    'Dismiss', 'Displace', 'Display', 'Dispose', 'Dispute', 'Disrupt',
    'Dissolve', 'Distinguish', 'Distort', 'Distract', 'Distress',
    'Distribute', 'Disturb', 'Dive', 'Divert', 'Divide', 'Divorce', 'Do',
    'Document', 'Dominate', 'Donate', 'Double', 'Doubt', 'Download',
    'Draft', 'Drag', 'Drain', 'Draw', 'Dream', 'Dress', 'Drift', 'Drink',
    'Drive', 'Drop', 'Dry', 'Dub', 'Dump', 'Earn', 'Ease', 'Eat',
    'Echo', 'Edit', 'Educate', 'Elect', 'Elevate', 'Eliminate', 'Email',
    'Embark', 'Embed', 'Embody', 'Embrace', 'Emerge', 'Emphasize', 'Employ',
    'Empower', 'Empty', 'Enable', 'Enact', 'Encompass', 'Encounter',
    'Encourage', 'End', 'Endorse', 'Endure', 'Enforce', 'Engage', 'Enhance',
    'Enjoy', 'Enquire', 'Enrich', 'Enrol', 'Ensue', 'Ensure', 'Enter',
    'Entertain', 'Entitle', 'Equal', 'Equip', 'Erect', 'Erupt', 'Escalate',
    'Escape', 'Establish', 'Estimate', 'Evacuate', 'Evaluate', 'Evoke',
    'Evolve', 'Exaggerate', 'Examine', 'Exceed', 'Exchange', 'Exclude',
    'Excuse', 'Execute', 'Exercise', 'Exert', 'Exhibit', 'Exist', 'Exit',
    'Expand', 'Expect', 'Experience', 'Experiment', 'Expire', 'Explain',
    'Explode', 'Exploit', 'Explore', 'Export', 'Expose', 'Express',
    'Extend', 'Extract', 'Face', 'Facilitate', 'Fade', 'Fail', 'Fall',
    'Fancy', 'Farm', 'Fasten', 'Favour', 'Fear', 'Feature', 'Feed', 'Feel',
    'Fight', 'Figure', 'File', 'Fill', 'Film', 'Filter', 'Finance', 'Find',
    'Fine', 'Finish', 'Fire', 'Fish', 'Fit', 'Fix', 'Flash', 'Flee',
    'Float', 'Flood', 'Flourish', 'Flow', 'Fly', 'Focus', 'Fold', 'Follow',
    'Forbid', 'Force', 'Forecast', 'Forge', 'Forget', 'Forgive', 'Form',
    'Formulate', 'Foster', 'Found', 'Frame', 'Free', 'Freeze', 'Frighten',
    'Fry', 'Fuel', 'Fulfil', 'Function', 'Fund', 'Gain', 'Gather', 'Gaze',
    'Generate', 'Get', 'Give', 'Glance', 'Go', 'Govern', 'Grab', 'Grade',
    'Graduate', 'Grant', 'Grasp', 'Greet', 'Grin', 'Grind', 'Grip', 'Grow',
    'Guarantee', 'Guard', 'Guess', 'Guide', 'Hail', 'Halt', 'Hand',
    'Handle', 'Hang', 'Happen', 'Harvest', 'Haunt', 'Have',
    'Head', 'Heal', 'Hear', 'Heat', 'Heighten', 'Help', 'Hesitate', 'Hide',
    'Highlight', 'Hint', 'Hire', 'Hit', 'Hold', 'Honour', 'Hook', 'Hope',
    'Host', 'House', 'Hunt', 'Hurry', 'Identify', 'Ignore',
    'Illustrate', 'Imagine', 'Impact', 'Implement', 'Imply', 'Import',
    'Impose', 'Impress', 'Improve', 'Include', 'Incorporate',
    'Increase', 'Incur', 'Indicate', 'Induce', 'Indulge', 'Infect', 'Infer',
    'Inflict', 'Influence', 'Inform', 'Inherit', 'Inhibit', 'Initiate',
    'Inject', 'Injure', 'Insert', 'Insist', 'Inspect', 'Inspire', 'Install',
    'Instruct', 'Insult', 'Integrate', 'Intend', 'Intensify', 'Interact',
    'Interest', 'Interfere', 'Interpret', 'Interrupt', 'Intervene',
    'Interview', 'Introduce', 'Invade', 'Invent', 'Invest', 'Investigate',
    'Invite', 'Invoke', 'Involve', 'Iron', 'Isolate', 'Issue',
    'Join', 'Joke', 'Judge', 'Jump', 'Justify', 'Keep', 'Key', 'Kick',
    'Kiss', 'Knock', 'Know', 'Label', 'Lack', 'Land',
    'Last', 'Laugh', 'Launch', 'Lay', 'Lead', 'Leak', 'Lean', 'Leap',
    'Learn', 'Leave', 'Lecture', 'Lend', 'Let', 'Level', 'License', 'Lift',
    'Light', 'Like', 'Limit', 'Line', 'Linger', 'Link', 'List', 'Listen',
    'Live', 'Load', 'Lobby', 'Locate', 'Lock', 'Log', 'Look', 'Loom',
    'Lose', 'Love', 'Lower', 'Mail', 'Maintain', 'Make', 'Manage',
    'Manifest', 'Manipulate', 'Manufacture', 'Map', 'March', 'Mark',
    'Market', 'Marry', 'Master', 'Match', 'Mate', 'Matter', 'Mature',
    'Maximize', 'Mean', 'Measure', 'Meet', 'Melt', 'Mention', 'Merge',
    'Mind', 'Minimize', 'Miss', 'Mistake', 'Mix', 'Mobilize', 'Model',
    'Modify', 'Monitor', 'Motivate', 'Mount', 'Move', 'Multiply',
    'Name', 'Narrow', 'Need', 'Neglect', 'Negotiate', 'Nod', 'Nominate',
    'Note', 'Notice', 'Notify', 'Number', 'Obey', 'Object', 'Oblige',
    'Observe', 'Obsess', 'Obtain', 'Occupy', 'Occur', 'Offend', 'Offer',
    'Open', 'Operate', 'Oppose', 'Opt', 'Order', 'Organize', 'Originate',
    'Outline', 'Outrage', 'Overcome', 'Overlook', 'Oversee', 'Overturn',
    'Overwhelm', 'Owe', 'Own', 'Pace', 'Pack', 'Package', 'Paint', 'Park',
    'Participate', 'Pass', 'Patrol', 'Pause', 'Pay', 'Perceive', 'Perform',
    'Permit', 'Persist', 'Persuade', 'Phone', 'Photograph', 'Pick',
    'Picture', 'Pile', 'Pin', 'Pioneer', 'Place', 'Plan', 'Plant', 'Play',
    'Plead', 'Please', 'Pledge', 'Plot', 'Plug', 'Plunge', 'Point',
    'Pop', 'Portray', 'Pose', 'Position', 'Possess', 'Post',
    'Postpone', 'Pour', 'Power', 'Practise', 'Praise', 'Pray', 'Preach',
    'Precede', 'Predict', 'Prefer', 'Prepare', 'Prescribe', 'Present',
    'Preserve', 'Preside', 'Press', 'Presume', 'Pretend', 'Prevail',
    'Prevent', 'Price', 'Print', 'Probe', 'Proceed', 'Process', 'Proclaim',
    'Produce', 'Program', 'Progress', 'Prohibit', 'Project', 'Promise',
    'Promote', 'Prompt', 'Pronounce', 'Propose', 'Prosecute', 'Protect',
    'Protest', 'Prove', 'Provide', 'Provoke', 'Publish', 'Pull', 'Pump',
    'Punch', 'Punish', 'Purchase', 'Pursue', 'Push', 'Put', 'Qualify',
    'Question', 'Queue', 'Quit', 'Quote', 'Race', 'Raid', 'Rain', 'Raise',
    'Rally', 'Range', 'Rank', 'Rate', 'Reach', 'React', 'Read',
    'Realize', 'Reassure', 'Rebuild', 'Recall', 'Receive', 'Reckon',
    'Recognize', 'Recommend', 'Record', 'Recount', 'Recover', 'Recruit',
    'Recycle', 'Reduce', 'Refer', 'Reflect', 'Reform', 'Refuse', 'Regain',
    'Regard', 'Register', 'Regret', 'Regulate', 'Reign', 'Reinforce',
    'Reject', 'Relate', 'Relax', 'Release', 'Relieve', 'Rely', 'Remain',
    'Remark', 'Remember', 'Remind', 'Remove', 'Render', 'Renew', 'Rent',
    'Repair', 'Repeat', 'Replace', 'Reply', 'Report', 'Represent',
    'Reproduce', 'Request', 'Require', 'Rescue', 'Research', 'Resemble',
    'Reserve', 'Reside', 'Resign', 'Resist', 'Resolve', 'Respect',
    'Respond', 'Rest', 'Restore', 'Restrict', 'Result', 'Resume', 'Retain',
    'Retire', 'Retreat', 'Retrieve', 'Return', 'Reveal', 'Reverse',
    'Review', 'Revise', 'Revive', 'Reward', 'Rid', 'Ride', 'Ring', 'Rip',
    'Rise', 'Risk', 'Rob', 'Rock', 'Roll', 'Rotate', 'Rub', 'Ruin', 'Rule',
    'Run', 'Rush', 'Sack', 'Sacrifice', 'Sail', 'Sample', 'Satisfy', 'Save',
    'Say', 'Scan', 'Scare', 'Schedule', 'Score', 'Scratch', 'Scream',
    'Screen', 'Screw', 'Seal', 'Search', 'Seat', 'Secure', 'See', 'Seek',
    'Seize', 'Select', 'Sell', 'Send', 'Sense', 'Sentence', 'Separate',
    'Serve', 'Set', 'Settle', 'Shake', 'Shape', 'Share', 'Shatter', 'Shed',
    'Shelter', 'Shift', 'Shine', 'Ship', 'Shock', 'Shoot', 'Shop', 'Shout',
    'Show', 'Shrink', 'Shrug', 'Shut', 'Sigh', 'Sign', 'Signal', 'Simulate',
    'Sing', 'Sink', 'Sit', 'Ski', 'Skip', 'Slam', 'Slap', 'Slash', 'Sleep',
    'Slice', 'Slide', 'Slip', 'Slope', 'Slow', 'Smash', 'Smell', 'Smile',
    'Smoke', 'Snap', 'Snow', 'Soak', 'Soar', 'Solve', 'Sort', 'Sound',
    'Span', 'Spare', 'Spark', 'Speak', 'Specialize', 'Specify', 'Speculate',
    'Speed', 'Spell', 'Spend', 'Spill', 'Spin', 'Split', 'Spoil', 'Sponsor',
    'Spot', 'Spread', 'Spring', 'Spy', 'Squeeze', 'Stab', 'Stabilize',
    'Stage', 'Stand', 'Star', 'Stare', 'Start', 'Starve', 'State', 'Stay',
    'Steal', 'Steer', 'Stem', 'Step', 'Stick', 'Stimulate', 'Stir', 'Stop',
    'Store', 'Strengthen', 'Stress', 'Stretch', 'Strike', 'Strip', 'Strive',
    'Structure', 'Struggle', 'Study', 'Stuff', 'Stumble', 'Stun', 'Submit',
    'Substitute', 'Succeed', 'Sue', 'Suffer', 'Suggest', 'Suit',
    'Sum', 'Summarize', 'Supervise', 'Supplement', 'Supply', 'Support',
    'Suppose', 'Suppress', 'Surge', 'Surprise', 'Surrender', 'Surround',
    'Survey', 'Survive', 'Suspect', 'Suspend', 'Sustain', 'Swallow',
    'Swear', 'Sweep', 'Swim', 'Swing', 'Switch', 'Tackle', 'Tag', 'Take',
    'Talk', 'Tap', 'Target', 'Taste', 'Tax', 'Teach', 'Tear', 'Telephone',
    'Tell', 'Tempt', 'Tend', 'Term', 'Terminate', 'Terrify', 'Test',
    'Testify', 'Text', 'Thank', 'Think', 'Thrive', 'Throw',
    'Tidy', 'Tie', 'Tighten', 'Time', 'Tip', 'Title', 'Tolerate', 'Top',
    'Toss', 'Total', 'Touch', 'Tour', 'Trace', 'Track', 'Trade',
    'Trail', 'Train', 'Transfer', 'Transform', 'Translate', 'Transmit',
    'Transport', 'Trap', 'Travel', 'Treat', 'Trick', 'Trigger', 'Trip',
    'Trouble', 'Trust', 'Try', 'Turn', 'Twist', 'Type', 'Undergo',
    'Undermine', 'Understand', 'Undertake', 'Unfold', 'Unify', 'Unite',
    'Unveil', 'Update', 'Upgrade', 'Uphold', 'Upset', 'Urge', 'Use',
    'Utilize', 'Value', 'Vanish', 'Vary', 'Venture', 'Verify', 'View',
    'Visit', 'Volunteer', 'Vote', 'Vow', 'Wait', 'Wake', 'Walk',
    'Wander', 'Want', 'Warm', 'Warn', 'Warrant', 'Wash', 'Waste', 'Watch',
    'Water', 'Wave', 'Weaken', 'Wear', 'Weave', 'Weigh', 'Welcome', 'Whip',
    'Whisper', 'Widen', 'Win', 'Wind', 'Wipe', 'Wish', 'Withdraw',
    'Witness', 'Wonder', 'Work', 'Worry', 'Worship', 'Wound', 'Wrap',
    'Write', 'Yell', 'Yield'
];

/**
 * The list of adverbs.
 * @const
 */
const _ADVERB_ = [
    'About', 'Above', 'Abroad', 'Absently', 'Absolutely', 'Accidentally',
    'Accordingly', 'Accurately', 'Accusingly', 'Across', 'Actually',
    'Additionally', 'Adequately', 'Adorably', 'After', 'Afterwards',
    'Again', 'Ago', 'Ahead', 'Alike', 'All', 'Allegedly', 'AllTheTime',
    'Almost', 'Alone', 'Along', 'Already', 'Also', 'Altogether', 'Always',
    'Amazingly', 'Angrily', 'Annually', 'Anxiously', 'Any', 'Anyway',
    'Anywhere', 'Apart', 'Appallingly', 'Apparently', 'Appropriately',
    'Approximately', 'Arguably', 'Around', 'Articulately', 'As', 'Aside',
    'Astonishingly', 'Automatically', 'Away', 'Back', 'Backwards', 'Badly',
    'Barely', 'Basically', 'Beautifully', 'Before', 'Behind', 'Below',
    'Besides', 'Best', 'Better', 'Between', 'Beyond', 'Blindly', 'Bravely',
    'Briefly', 'Brightly', 'Briskly', 'Broadly', 'By', 'Calmly',
    'Carefully', 'Casually', 'Cautiously', 'Certainly', 'Cheaply', 'Clearly',
    'Cleverly', 'Close', 'Closely', 'Commonly', 'Completely',
    'Consequently', 'Considerably', 'Consistently', 'Constantly',
    'Continually', 'Correctly', 'Crazily', 'Critically', 'Curiously',
    'Currently', 'Cynically', 'Daily', 'Dangerously', 'Deeply',
    'Definitely', 'Deliberately', 'Delicately', 'Desperately',
    'Differently', 'Directly', 'Discreetly', 'Double', 'Down',
    'Downstairs', 'Downtown', 'Downwards', 'Dramatically', 'Each',
    'Eagerly', 'Early', 'Easily', 'East', 'Effectively', 'Efficiently',
    'Either', 'Else', 'Elsewhere', 'Emotionally', 'Enough', 'Entirely',
    'Equally', 'Especially', 'Essentially', 'Euphoricly', 'Even', 'Evenly',
    'Eventually', 'Ever', 'Everywhere', 'Exactly', 'Exclusively',
    'Expectantly', 'Explicitly', 'Extensively', 'Extra', 'Extremely',
    'Fairly', 'Far', 'Fast', 'Ferociously', 'Fiercely', 'Finally', 'Finely',
    'Firmly', 'First', 'Firstly', 'Flatly', 'Forever', 'Formerly', 'Forth',
    'Fortunately', 'Forward', 'Frankly', 'Free', 'Freely', 'Frequently',
    'Frighteningly', 'FullTime', 'Fully', 'Fundamentally', 'Further',
    'Furthermore', 'Generally', 'Gently', 'Genuinely', 'Gloriously',
    'Gradually', 'Greatly', 'Grimly', 'Guiltily', 'Half', 'Halfway',
    'Happily', 'Hard', 'Hardly', 'Hastily', 'Heavily', 'Hence', 'Here',
    'Heroically', 'High', 'Highly', 'Home', 'Hopefully', 'Hourly', 'How',
    'However', 'Humbly', 'Hysterically', 'Immediately', 'Immensely',
    'Impartially', 'Impolitely', 'In', 'Increasingly', 'Incredibly',
    'Indeed', 'Indifferently', 'Indoors', 'Inevitably', 'Initially',
    'Inside', 'Instantly', 'Instead', 'Intensely', 'Ironically',
    'Jealously', 'Jovially', 'Just', 'Kindly', 'Largely', 'Last', 'Late',
    'Lately', 'Later', 'Lazily', 'Least', 'Left', 'Less', 'Lightly',
    'Likewise', 'Literally', 'Little', 'Live', 'Long', 'LongTerm', 'Lot',
    'Loud', 'Loudly', 'Lovingly', 'Low', 'Loyally', 'Magnificently',
    'Mainly', 'Maybe', 'Meanwhile', 'Merely', 'Merrily',
    'Mightily', 'Miserably', 'More', 'Moreover', 'Most', 'Mostly', 'Much',
    'Mysteriously', 'Namely', 'Naturally', 'Near', 'Nearby', 'Nearly',
    'Necessarily', 'Neither', 'Nervously', 'Never', 'Nevertheless', 'Newly',
    'Next', 'Nicely', 'Nonetheless', 'Nor', 'Normally', 'North', 'Not',
    'NOT', 'Notably', 'Now', 'Nowadays', 'Nowhere',
    'Objectively', 'Obnoxiously', 'Obsessively', 'Obviously',
    'Occasionally', 'Off', 'Often', 'Ok', 'On', 'Once', 'Online', 'Only',
    'Openly', 'Opposite', 'Originally', 'Otherwise', 'Out', 'Outdoors',
    'Outside', 'Over', 'Overall', 'Overly', 'Overnight', 'Overseas',
    'Painfully', 'Partially', 'Particularly', 'Partly', 'Past', 'Patiently',
    'Perfectly', 'Perhaps', 'Permanently', 'Personally', 'Playfully',
    'Politely', 'Poorly', 'Possibly', 'Potentially', 'Precisely',
    'Predominantly', 'Presently', 'Presumably', 'Pretty', 'Previously',
    'Primarily', 'Probably', 'Promptly', 'Properly', 'Purely', 'Quickly',
    'Quietly', 'Quite', 'Randomly', 'Rapidly', 'Rarely', 'Rather',
    'Readily', 'Really', 'Reasonably', 'Recently', 'Recklessly',
    'Regardless', 'Regularly', 'Relatively', 'Remarkably', 'Remorsefully',
    'Reportedly', 'Respectively', 'Responsibly', 'Right', 'Roughly',
    'Round', 'Rudely', 'Ruthlessly', 'Sadly', 'Same', 'Scornfully',
    'Seamlessly', 'Second', 'Secondly', 'Seemingly', 'Seldom', 'Selfishly',
    'Seriously', 'Severely', 'Shakily', 'Sharply', 'Shortly', 'Sideways',
    'Significantly', 'Silently', 'Similarly', 'Simply', 'Simultaneously',
    'Since', 'Sleepily', 'Slightly', 'Slowly', 'Slyly', 'Smoothly', 'So',
    'Softly', 'Solely', 'Solemnly', 'Somehow', 'Sometime', 'Sometimes',
    'Somewhat', 'Somewhere', 'Soon', 'South', 'Specifically', 'Steadily',
    'Sternly', 'Still', 'Straight', 'Strangely', 'Strictly', 'Strongly',
    'Stunningly', 'Subsequently', 'Substantially', 'Successfully',
    'Suddenly', 'Sufficiently', 'Supposedly', 'Sure', 'Surely',
    'Temporarily', 'Tenderly', 'Terribly', 'Thankfully', 'That', 'Then',
    'There', 'Thereafter', 'Thereby', 'Therefore', 'This', 'Thoroughly',
    'Though', 'Thoughtfully', 'Through', 'Throughout', 'Thus', 'Tightly',
    'Today', 'Together', 'Tomorrow', 'Tonight', 'Too', 'Totally', 'Truly',
    'Twice', 'Typically', 'Ultimately', 'Under', 'Underground',
    'Undoubtedly', 'Uneasily', 'Unfortunately', 'Up', 'Upstairs', 'Upwards',
    'Usually', 'Utterly', 'Vanishingly', 'Very', 'Warmly',
    'Way', 'Weakly', 'Wearily', 'Weekly', 'Weirdly', 'Well', 'West',
    'Whatever', 'Whatsoever', 'When', 'Where', 'Whereby', 'Wholly', 'Why',
    'Wickedly', 'Widely', 'Wildly', 'Wisely', 'Wonderfully', 'Worldwide',
    'Worse', 'Worst', 'Wrong', 'Yearly', 'Yesterday', 'Yet'
];

/**
 * The list of adjectives.
 * @const
 */
const _ADJECTIVE_ = [
    'Able', 'Absent', 'Absolute', 'Abstract', 'Absurd', 'Academic',
    'Acceptable', 'Accessible', 'Accountable', 'Accurate', 'Acid', 'Active',
    'Actual', 'Acute', 'Additional', 'Adequate', 'Adjacent',
    'Administrative', 'Adult', 'Advance', 'Advanced', 'Adverse',
    'Aesthetic', 'Affordable', 'Afraid', 'Aged',
    'Agricultural', 'Alert', 'Alien', 'Alike', 'Alive',
    'Alone', 'Alternative', 'Amateur', 'Amazed', 'Amazing', 'Ambitious',
    'Amusing', 'Ancient', 'Angry', 'Annoyed', 'Annoying', 'Annual',
    'Anonymous', 'Anxious', 'Apparent', 'Appealing', 'Applicable',
    'Appropriate', 'Arbitrary', 'Architectural', 'Artificial',
    'Artistic', 'Ashamed', 'Asleep', 'Assistant', 'Associated',
    'Astonishing', 'Attractive', 'Audio', 'Authentic', 'Automatic',
    'Available', 'Average', 'Aware', 'Awful', 'Awkward', 'Back', 'Bad',
    'Balanced', 'Bare', 'Based', 'Basic', 'Beautiful', 'Beloved',
    'Beneficial', 'Bent', 'Best', 'Better', 'Big', 'Biological', 'Bitter',
    'Bizarre', 'Blank', 'Blind', 'Blonde', 'Blue', 'Bold', 'Bored',
    'Boring', 'Bottom', 'Bound', 'Brave', 'Brief', 'Bright', 'Brilliant',
    'Broad', 'Broken', 'Brown', 'Busy', 'Calm', 'Capable',
    'Capital', 'Capitalist', 'Careful', 'Careless', 'Casual', 'Cautious',
    'Central', 'Certain', 'Challenging', 'Characteristic', 'Charming',
    'Cheap', 'Cheerful', 'Chemical', 'Chief', 'Chronic', 'Civic', 'Civil',
    'Civilian', 'Classic', 'Classical', 'Clean', 'Clear', 'Clever',
    'Clinical', 'Close', 'Closed', 'Coastal', 'Cognitive', 'Cold',
    'Collective', 'Colonial', 'Coloured', 'Colourful', 'Comfortable',
    'Comic', 'Commercial', 'Common', 'Communist', 'Comparable',
    'Comparative', 'Compelling', 'Competent', 'Competitive', 'Complete',
    'Complex', 'Complicated', 'Comprehensive', 'Compulsory', 'Concerned',
    'Concrete', 'Confident', 'Confused', 'Confusing', 'Congressional',
    'Connected', 'Conscious', 'Consecutive', 'Conservative', 'Considerable',
    'Consistent', 'Constant', 'Constitutional', 'Contemporary', 'Content',
    'Continuous', 'Contrary', 'Controversial', 'Convenient', 'Conventional',
    'Convinced', 'Convincing', 'Cool', 'Cooperative', 'Core', 'Corporate',
    'Correct', 'Corresponding', 'Costly', 'Countless', 'Covered',
    'Cream', 'Creative', 'Credible', 'Critical',
    'Crowded', 'Crucial', 'Crude', 'Cruel', 'Cult', 'Cultural', 'Curious',
    'Curly', 'Current', 'Curved', 'Cute', 'Cynical', 'Daily', 'Dairy',
    'Damaging', 'Dangerous', 'Dark', 'Dead', 'Dear', 'Decent',
    'Decisive', 'Dedicated', 'Deep', 'Defensive', 'Definite', 'Deliberate',
    'Delicate', 'Delicious', 'Delighted', 'Democratic', 'Dense',
    'Dependent', 'Depressed', 'Depressing', 'Desirable', 'Desperate',
    'Destructive', 'Detailed', 'Determined', 'Different', 'Difficult',
    'Digital', 'Diplomatic', 'Direct', 'Dirty', 'Disabled', 'Disappointed',
    'Disappointing', 'Disastrous', 'Dishonest', 'Distant', 'Distinct',
    'Distinctive', 'Disturbing', 'Diverse', 'Divine', 'Divorced',
    'Domestic', 'Dominant', 'Double', 'Downstairs', 'Downtown', 'Dramatic',
    'Dressed', 'Driving', 'Dry', 'Dual', 'Due', 'Dull', 'Dumb',
    'Dynamic', 'Eager', 'Early', 'East', 'Eastern', 'Easy', 'Ecological',
    'Economic', 'Editorial', 'Educated', 'Educational', 'Effective',
    'Efficient', 'Elaborate', 'Elderly', 'Electoral', 'Electric',
    'Electrical', 'Electronic', 'Elegant', 'Elementary', 'Eligible',
    'Embarrassed', 'Embarrassing', 'Emotional', 'Empirical', 'Empty',
    'Encouraging', 'Endless', 'Engaged', 'Engaging', 'Enjoyable',
    'Enormous', 'Entertaining', 'Enthusiastic', 'Entire', 'Environmental',
    'Equal', 'Equivalent', 'Essential', 'Eternal', 'Ethical', 'Ethnic',
    'Even', 'Everyday', 'Evident', 'Evil', 'Evolutionary', 'Exact',
    'Excellent', 'Exceptional', 'Excess', 'Excessive', 'Excited',
    'Exciting', 'Exclusive', 'Executive', 'Exotic', 'Expected', 'Expensive',
    'Experienced', 'Experimental', 'Expert', 'Explicit', 'Explosive',
    'Extensive', 'External', 'Extra', 'Extraordinary', 'Extreme',
    'Fabulous', 'Failed', 'Fair', 'Fake', 'False', 'Familiar', 'Family',
    'Famous', 'Fancy', 'Fantastic', 'Far', 'Fascinating', 'Fashionable',
    'Fast', 'Fat', 'Fatal', 'Favourable', 'Favourite', 'Federal', 'Fellow',
    'Feminist', 'Few', 'Fierce', 'Final', 'Financial', 'Fine',
    'Firm', 'Fit', 'Fixed', 'Flat', 'Flawed', 'Flexible', 'Flying',
    'Folding', 'Folk', 'Following', 'Fond', 'Foreign', 'Formal', 'Former',
    'Forthcoming', 'Fortunate', 'Forward', 'Fragile', 'Free', 'Frequent',
    'Fresh', 'Friendly', 'Frightened', 'Frightening', 'Front', 'Frozen',
    'Frustrated', 'Frustrating', 'Full', 'FullTime', 'Fun', 'Functional',
    'Fundamental', 'Funny', 'Furious', 'Further', 'Future',
    'General', 'Generic', 'Generous', 'Genetic', 'Gentle', 'Genuine',
    'Giant', 'Glad', 'Global', 'Glorious', 'Gold', 'Golden', 'Good',
    'Gorgeous', 'Grand', 'Graphic', 'Grateful', 'Grave', 'Great', 'Green',
    'Grey', 'Gross', 'Guilty', 'Handy', 'Happy', 'Hard', 'Harmful', 'Harsh',
    'Healthy', 'Heavy', 'Helpful', 'Hidden', 'High', 'HighProfile',
    'Hilarious', 'Historic', 'Historical', 'Hollow', 'Holy', 'Home',
    'Homeless', 'Honest', 'Hopeful', 'Horrible', 'Hot', 'Huge',
    'Human', 'Humanitarian', 'Humble', 'Humorous', 'Hungry', 'Hurt',
    'Ideal', 'Identical', 'Ideological', 'Ill', 'Illegal', 'Imaginary',
    'Immediate', 'Immense', 'Imminent', 'Immune', 'Impatient', 'Important',
    'Impossible', 'Impressed', 'Impressive', 'Inadequate', 'Inappropriate',
    'Inclined', 'Included', 'Incorrect', 'Incredible', 'Independent',
    'Indigenous', 'Indirect', 'Individual', 'Indoor', 'Industrial',
    'Inevitable', 'Infamous', 'Influential', 'Informal', 'Inherent',
    'Initial', 'Injured', 'Inner', 'Innocent', 'Innovative', 'Inside',
    'Instant', 'Institutional', 'Instrumental', 'Insufficient', 'Intact',
    'Integral', 'Integrated', 'Intellectual', 'Intelligent', 'Intended',
    'Intense', 'Intensive', 'Interactive', 'Interested', 'Interesting',
    'Interim', 'Interior', 'Intermediate', 'Internal', 'International',
    'Intimate', 'Intriguing', 'Invisible', 'Involved', 'Ironic',
    'Irrelevant', 'Isolated', 'Joint', 'Judicial', 'Junior', 'Just', 'Keen',
    'Key', 'Kind', 'Large', 'LargeScale', 'Late', 'Later', 'Latest',
    'Latter', 'Lazy', 'Leading', 'Left', 'Legal', 'Legendary',
    'Legislative', 'Legitimate', 'Lengthy', 'Lesser',
    'Level', 'Liable', 'Liberal', 'Lifelong', 'Light', 'Likely', 'Limited',
    'Linear', 'Liquid', 'Literary', 'Little', 'Live', 'Lively', 'Living',
    'Local', 'Located', 'Logical', 'Lonely', 'Long', 'LongStanding',
    'LongTerm', 'LongTime', 'Loose', 'Lost', 'Loud', 'Lovely', 'Low',
    'Loyal', 'Lucky', 'Luxury', 'Mad', 'Magic', 'Magical', 'Magnetic',
    'Magnificent', 'Main', 'Mainstream', 'Major', 'Mandatory',
    'Marginal', 'Marine', 'Married', 'Martial', 'Mass', 'Massive',
    'Matching', 'Material', 'Mathematical', 'Mature', 'Maximum',
    'Meaningful', 'Mechanical', 'Medical', 'Medieval', 'Medium',
    'Memorable', 'Mental', 'Mere', 'Middle', 'Mild',
    'Minimal', 'Minimum', 'Minute', 'Miserable', 'Misleading',
    'Missing', 'Mixed', 'Mobile', 'Moderate', 'Modern', 'Modest', 'Monthly',
    'Moral', 'Motor', 'Moving', 'Multiple', 'Municipal', 'Musical',
    'Mutual', 'Mysterious', 'Narrative', 'Narrow',
    'National', 'Nationwide', 'Native', 'Natural', 'Naval', 'Near',
    'Nearby', 'Neat', 'Necessary', 'Negative', 'Neighbouring', 'Nervous',
    'Net', 'Neutral', 'New', 'Next', 'Nice', 'Noble', 'Noisy', 'NonProfit',
    'Normal', 'North', 'Northern', 'Notable', 'Notorious', 'Novel',
    'Nuclear', 'Numerous', 'Objective', 'Obvious', 'Occasional', 'Odd',
    'Offensive', 'Official', 'Ok', 'Old', 'OldFashioned', 'Ongoing',
    'Online', 'Only', 'Open', 'Operational', 'Opposed', 'Opposite',
    'Optical', 'Optimistic', 'Orange', 'Ordinary', 'Organic',
    'Organizational', 'Organized', 'Original', 'Other', 'Outdoor', 'Outer',
    'Outside', 'Outstanding', 'Overall', 'Overseas', 'Overwhelming', 'Own',
    'Painful', 'Pale', 'Parallel', 'Parental', 'Parliamentary', 'PartTime',
    'Partial', 'Particular', 'Passionate', 'Passive', 'Past', 'Patient',
    'Peaceful', 'Peculiar', 'Perfect', 'Permanent', 'Persistent',
    'Personal', 'Philosophical', 'Physical', 'Pink', 'Plain', 'Plastic',
    'Pleasant', 'Pleased', 'Plus', 'Pointed', 'Poisonous', 'Polite',
    'Political', 'Poor', 'Pop', 'Popular', 'Positive', 'Possible',
    'PostWar', 'Potential', 'Powerful', 'Practical', 'Precious', 'Precise',
    'Predictable', 'Preliminary', 'Premier', 'Prepared',
    'Present', 'Presidential', 'Prestigious', 'Pretty', 'Previous',
    'Primary', 'Prime', 'Principal', 'Prior', 'Private', 'Probable',
    'Problematic', 'Productive', 'Professional', 'Profitable', 'Profound',
    'Progressive', 'Prominent', 'Promising', 'Pronounced', 'Proper',
    'Prospective', 'Protective', 'Proud', 'Provincial', 'Psychiatric',
    'Psychological', 'Public', 'Pure', 'Purple', 'Qualified', 'Quick',
    'Quiet', 'Radical', 'Random', 'Rapid', 'Rare',
    'Rational', 'Raw', 'Ready', 'Real', 'Realistic', 'Rear', 'Reasonable',
    'Recent', 'Red', 'Regional', 'Regular', 'Regulatory', 'Related',
    'Relative', 'Relaxed', 'Relaxing', 'Relevant', 'Reliable', 'Relieved',
    'Religious', 'Reluctant', 'Remarkable', 'Remote', 'Renowned',
    'Repeated', 'Representative', 'Resident', 'Residential', 'Respective',
    'Responsible', 'Retired', 'Reverse', 'Revolutionary', 'Rich',
    'Ridiculous', 'Right', 'Risky', 'Rival', 'Robust', 'Romantic', 'Rough',
    'Round', 'Routine', 'Royal', 'Rubber', 'Rude', 'Rural', 'Sacred', 'Sad',
    'Safe', 'Same', 'Satisfied', 'Scary', 'Scattered',
    'Sceptical', 'Scientific', 'Secondary', 'Secret', 'Secular', 'Secure',
    'Selective', 'Senior', 'Sensible', 'Sensitive', 'Separate', 'Serial',
    'Serious', 'Severe', 'Shallow', 'Shaped', 'Sharp',
    'Sheer', 'Shiny', 'Shocked', 'Shocking', 'Short', 'ShortTerm', 'Shut',
    'Shy', 'Significant', 'Silent', 'Silly', 'Silver', 'Similar',
    'Simple', 'Sincere', 'Single', 'Situated', 'Ski', 'Skilled', 'Slight',
    'Slow', 'Small', 'Smart', 'Smooth', 'SoCalled', 'Social', 'Socialist',
    'Soft', 'Solar', 'Sole', 'Solid', 'Solo', 'Sophisticated',
    'Sound', 'South', 'Spare', 'Special', 'Specialist',
    'Specialized', 'Specific', 'Spectacular', 'Spicy', 'Spiritual',
    'Spoken', 'Sporting', 'Square', 'Stable', 'Standard', 'Standing',
    'Stark', 'State', 'Statistical', 'Steady', 'Steep', 'Sticky', 'Stiff',
    'Still', 'Straight', 'Straightforward', 'Strange', 'Strategic',
    'Strict', 'Striking', 'Strong', 'Structural', 'Stunning',
    'Subject', 'Subsequent', 'Substantial', 'Subtle', 'Suburban',
    'Successful', 'Successive', 'Sudden', 'Sufficient', 'Suitable', 'Super',
    'Superb', 'Superior', 'Supportive', 'Supreme', 'Sure', 'Surgical',
    'Surprised', 'Surprising', 'Surrounding', 'Suspicious', 'Sustainable',
    'Sweet', 'Symbolic', 'Sympathetic', 'Systematic', 'Tactical',
    'Talented', 'Tall', 'Technical', 'Technological',
    'Temporary', 'Tender', 'Terminal', 'Terrible', 'Terrific', 'Theatrical',
    'Theoretical', 'Thick', 'Thin', 'Thirsty', 'Thorough',
    'ThoughtProvoking', 'Thoughtful', 'Thrilled', 'Tidy', 'Tight',
    'Timely', 'Tiny', 'Tired', 'Top', 'Total', 'Tough', 'Toxic', 'Toy',
    'Traditional', 'Transparent', 'Tremendous', 'Tribal',
    'Tropical', 'True', 'Twin', 'Typical', 'Ultimate',
    'Unable', 'Unacceptable', 'Uncomfortable', 'Underground',
    'Underlying', 'Unemployed', 'Unexpected', 'Unfair', 'Unfortunate',
    'Unique', 'United', 'Universal', 'Unknown', 'Unlikely',
    'Unnecessary', 'Unpleasant', 'Unprecedented', 'Unusual', 'Upcoming',
    'Upper', 'Upset', 'Upstairs', 'Urban', 'Urgent', 'Used', 'Useful',
    'Useless', 'Usual', 'Vague', 'Valid', 'Valuable', 'Variable', 'Varied',
    'Various', 'Vast', 'Verbal', 'Vertical', 'Very', 'Viable', 'Vibrant',
    'Virtual', 'Visible', 'Visual', 'Vital', 'Vocal',
    'Voluntary', 'Vulnerable', 'Warm', 'Waste', 'Weak', 'Wealthy', 'Weekly',
    'Weird', 'Welcome', 'Well', 'West', 'Western', 'Wet', 'White', 'Whole',
    'Wide', 'Widespread', 'Wild', 'Willing', 'Wise', 'Wonderful', 'Wooden',
    'Working', 'Worldwide', 'Worried', 'Worse', 'Worst', 'Worth',
    'Worthwhile', 'Worthy', 'Written', 'Wrong', 'Yellow'
];

/*
const _PRONOUN_ = [
];
*/

/*
const _CONJUNCTION_ = [
    'And', 'Or', 'For', 'Above', 'Before', 'Against', 'Between'
];
*/

/**
 * Maps a string (category name) to the array of words from that category.
 * @const
 */
const CATEGORIES = {
    _ADJECTIVE_,
    _ADVERB_,
    _PLURALNOUN_,
    _VERB_

//    _CONJUNCTION_,
//    _NOUN_,
//    _PLACE_,
//    _PRONOUN_,
};

/**
 * The list of room name patterns.
 * @const
 */
const PATTERNS = [
    '_ADJECTIVE__PLURALNOUN__VERB__ADVERB_'

    // BeautifulFungiOrSpaghetti
    //    '_ADJECTIVE__PLURALNOUN__CONJUNCTION__PLURALNOUN_',

    // AmazinglyScaryToy
    //    '_ADVERB__ADJECTIVE__NOUN_',

    // NeitherTrashNorRifle
    //    'Neither_NOUN_Nor_NOUN_',
    //    'Either_NOUN_Or_NOUN_',

    // EitherCopulateOrInvestigate
    //    'Either_VERB_Or_VERB_',
    //    'Neither_VERB_Nor_VERB_',

    //    'The_ADJECTIVE__ADJECTIVE__NOUN_',
    //    'The_ADVERB__ADJECTIVE__NOUN_',
    //    'The_ADVERB__ADJECTIVE__NOUN_s',
    //    'The_ADVERB__ADJECTIVE__PLURALNOUN__VERB_',

    // WolvesComputeBadly
    //    '_PLURALNOUN__VERB__ADVERB_',

    // UniteFacilitateAndMerge
    //    '_VERB__VERB_And_VERB_',

    // NastyWitchesAtThePub
    //    '_ADJECTIVE__PLURALNOUN_AtThe_PLACE_',
];

/**
 * Generates a new room name.
 *
 * @returns {string} A newly-generated room name.
 */
export function generateRoomWithoutSeparator() {
    // XXX Note that if more than one pattern is available, the choice of 'name'
    // won't have a uniform distribution amongst all patterns (names from
    // patterns with fewer options will have higher probability of being chosen
    // that names from patterns with more options).
    let name = randomElement(PATTERNS);

    while (_hasTemplate(name)) {
        for (const template in CATEGORIES) { // eslint-disable-line guard-for-in
            const word = randomElement(CATEGORIES[template]);

            name = name.replace(template, word);
        }
    }

    return name;
}

/**
 * Determines whether a specific string contains at least one of the
 * templates/categories.
 *
 * @param {string} s - String containing categories.
 * @private
 * @returns {boolean} True if the specified string contains at least one of the
 * templates/categories; otherwise, false.
 */
function _hasTemplate(s) {
    for (const template in CATEGORIES) {
        if (s.indexOf(template) >= 0) {
            return true;
        }
    }

    return false;
}

/*
function findDuplicates(array) {
    var dups = array.reduce(function (acc, cur) {
        if (!acc[cur]) {
            acc[cur] = 1;
        } else {
            acc[cur] += 1;
        }

        return acc;
    }, {});
    for (const word in dups) {
        if (dups[word] > 1) {
            console.log(`Duplicate: ${word} ${dups[word]}`);
        }
    }
}
findDuplicates(_ADJECTIVE_);
findDuplicates(_PLURALNOUN_);
findDuplicates(_VERB_);
findDuplicates(_ADVERB_);
var combinations = _ADJECTIVE_.length * _PLURALNOUN_.length * _VERB_.length * _ADVERB_.length;
console.log(`${combinations} combinations (${Math.log2(combinations)} bits of entropy)`)
*/
