import { CardGroup, OddsCalculator } from 'poker-odds-calculator';
import { Hand } from 'pokersolver';

const makeString = cards => cards.join("")

const calculateOdds = (p1, p2, table) => {
  const player1Cards = CardGroup.fromString(makeString(p1));
  const player2Cards = CardGroup.fromString(makeString(p2));
  const tableCards = CardGroup.fromString(makeString(table));

  const result = OddsCalculator.calculate([player1Cards, player2Cards], tableCards);

  const player1 = result.equities[0].getEquity()
  const player2 = result.equities[1].getEquity()
  const tie = 100 - player1 - player2

  return { player1, player2, tie }
}

const getHand = (p, table) => {
  const cards = p.concat(table)
  var hand = Hand.solve(cards)
  hand['winner'] = hand.cards.map(c => c.value.concat(c.suit))
  return hand
}

const getWinningCards = (hands, board) => {
  let solvedhands = hands.map(h => {
    const cards = h.concat(board)
    return Hand.solve(cards) })

  let winners = Hand.winners(solvedhands)
  let winninghands = winners.map(h => h.cards.map(c => c.value.concat(c.suit)))

  const mergeDedupe = (arr) => {
    return [...new Set([].concat(...arr))];
  }

  return { winningcards: mergeDedupe(winninghands), descr: winners[0].descr}
}

export { calculateOdds, getHand, getWinningCards }
