import React from 'react'


function Button(props) {

    return (
        <div className={props.hidden ? "actnbtn hidden" : "actnbtn"} onClick={props.onClick}>
            {props.title}
        </div>
    )

}

export default Button
