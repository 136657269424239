import React from 'react';
import Modal from './Modal'

class ModalCollection extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
            show: null,
            value: '',
            data: null
        }
        this.onCancel = this.onCancel.bind(this);
        this.onOk = this.onOk.bind(this);

    }

    showHandler = (type, data) => {
        this.setState({ show: type, value: '', data: data})
    }

    onCancel = () => {
        this.setState({ show: null, data: null })
    }

    onOk = () => {
        this.props.modalAction({type: this.state.show, data: {nick: this.state.data, amount: this.state.value}})
        this.setState({ show: null, data: null })
    }

    onChange = (event) => {
        this.setState({ value: event.target.value})
    }

    render() {
        if (!this.state.show) {
            return null
        }
        let title = 'ERROR: Unsupported modal'
        switch (this.state.show) {
            case 'tip':
                title = 'Tip ' + this.state.data
                break;
            case 'buyin':
                title = 'Buy-in amount for ' + this.state.data
                break;
            default:
                break;
        }

        return(
        <Modal show="true" onCancel={this.onCancel} onOk={this.onOk}>
            {title}
            <form>
                <input autoFocus="True" className="modalinput" type="text" onChange={this.onChange} value={this.state.value} />
            </form>
        </Modal>)
}
}

export default ModalCollection
