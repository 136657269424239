import React from 'react'

class Jitsi extends React.Component {
    constructor(props) {
        super(props)
        this.jitsiref = React.createRef()
    }

    componentDidMount() {
        const domain = 'meet.jit.si';
        const options = {
            roomName: this.props.room,
            height: 190,
            parentNode: this.jitsiref.current,
            interfaceConfigOverwrite: { filmStripOnly: true, VERTICAL_FILMSTRIP: false }
        };
        const api = new window.JitsiMeetExternalAPI(domain, options);

    }

    render() {
        return (
            <div className="jitsi-containter">
                <div className="jitsi" ref={this.jitsiref}></div>
            </div>
        )
    }
}

export default Jitsi
