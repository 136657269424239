import React from 'react'
import Game from './Game'
import Welcome from './Welcome'

import { ToastProvider, useToasts } from 'react-toast-notifications'

function WrappedApp() {
    let path = window.location.pathname.split('/')
    var content = ''
    const { addToast, removeToast } = useToasts()

    // Todo: Check if path does not contain scary stuff?
    if (path.length > 2 && path[1] !== '' && path[2] !== '') {
        content = <Game room={path[1]} nick={path[2]} addToast={addToast} removeToast={removeToast} host={window.location.host} />
    } else {
        let room = ''
        if (path.length >= 2 && path[1] !== '') {
            room = path[1]
        }
        content = <Welcome room={room} />
    }
    return content
}

function App() {
    return (
        <ToastProvider
            autoDismiss
            autoDismissTimeout={6000}>
            <WrappedApp />
        </ToastProvider>
    )
}

export default App
