import React from 'react'
import RaiseDisplay from './RaiseDisplay'
import CheckboxButton from './CheckboxButton';
import Button from './Button';

function ActionDisplay(props) {
    let hideraise = props.minraise > props.maxraise

    let leavetitle = props.state === "SIT_OUT" ? "Leave" : "Sit out"
    let hidejoin = props.state !== "SIT_OUT" || props.inround
    return (
        <div className="action-container">
            {/*
            <div className="btn-cont">
            <Button
                onClick={props.Shuffle}
                title="Shuffle seats"
                hidden={props.inround}
            />
            </div>
            */}
            <div className="btn-cont">
            <Button
                onClick={props.Leave}
                title={leavetitle}
                hidden={props.inround}
            />
            </div>
            <div className="btn-cont">
                <Button
                    onClick={props.Join}
                    title="Join"
                    hidden={hidejoin}
                />
            </div>
            <div className="btn-cont">
            <Button
                onClick={props.Deal}
                title="Deal"
                hidden={props.inround}
            />
            </div>
            <div className="btn-cont">
            <CheckboxButton
                checked={props.foldnext}
                onClick={props.Fold}
                showcheck={!props.isactive}
                title="Fold"
                hidden={!props.inround}
            />
            </div>
            <div className="btn-cont">
            <Button
                onClick={props.Checkcall}
                title={props.checkorcall}
                hidden={!props.inround || !props.isactive}
            />
            </div>
            <div className="btn-cont">
            <RaiseDisplay
                pot={props.pot}
                maxraise={props.maxraise}
                minraise={props.minraise}
                Raise={props.Raise}
                hidden={!props.inround || !props.isactive || hideraise}
            />
            </div>
        </div>
    )
}

export default ActionDisplay
