import React from 'react';
import PropTypes from 'prop-types';

class Modal extends React.Component {
    render() {
        return (
            <div className="backdrop">
                <div className="modal">
                    {this.props.children}

                    <div className="footer">
                        <button onClick={this.props.onOk}>
                            Ok
                        </button>
                        <button onClick={this.props.onCancel}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

Modal.propTypes = {
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    children: PropTypes.node
};

export default Modal;
