import React, { Component } from 'react'
import { generateRoomWithoutSeparator } from './random';
import stripunsafe from './helpers/mischelpers'

class Welcome extends Component {

    constructor(props) {
        super(props)
        this.state = {
            room: this.props.room,
            nick: "",
            animateTimeoutId: undefined,
            updateTimeoutId: undefined,
            generatedRoomname: '',
            roomPlaceholder: '',
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleNickChange = this.handleNickChange.bind(this)
        this.join = this.join.bind(this)
        this.animateRoomnameChanging
            = this.animateRoomnameChanging.bind(this);
        this.updateRoomname = this.updateRoomname.bind(this);
    }

    componentDidMount() {
        this.updateRoomname();
    }

    animateRoomnameChanging(word) {
        let animateTimeoutId;
        const roomPlaceholder = this.state.roomPlaceholder + word.substr(0, 1);

        if (word.length > 1) {
            animateTimeoutId
                = setTimeout(
                    () => {
                        this.animateRoomnameChanging(
                            word.substring(1, word.length));
                    },
                    70);
        }
        this.setState({
            animateTimeoutId,
            roomPlaceholder
        });
    }

    updateRoomname() {
        const generatedRoomname = generateRoomWithoutSeparator();
        const roomPlaceholder = '';
        const updateTimeoutId = setTimeout(this.updateRoomname, 10000);

        this.clearTimeouts();
        this.setState(
            {
                generatedRoomname,
                roomPlaceholder,
                updateTimeoutId
            },
            () => this.animateRoomnameChanging(generatedRoomname));
    }

    clearTimeouts() {
        clearTimeout(this.state.animateTimeoutId);
        clearTimeout(this.state.updateTimeoutId);
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.join();
    }

    handleChange = (event) => {
        this.setState({
            room: stripunsafe(event.target.value)
        })
    }
    handleNickChange = (event) => {
        this.setState({
            nick: stripunsafe(event.target.value)
        })
    }
    join = () => {
        if (!this.state.nick) {
            alert("Please enter a nick!")
        } else {
            let room = this.state.room ? this.state.room : this.state.generatedRoomname
            window.location.href = window.location.protocol + "//" + window.location.host + "/" + room + '/' + this.state.nick
        }
    }

    render() {
        document.body.style.background = 'rgb(0, 116, 224)'
        return (

            <div id="welcomescreen">
                <div className="Welcome text">
                    <h1>PokerDigs</h1>
                </div>
                <div className="introtext"><p>Play Texas Hold'em with your friends from the comfort of your couch. Built-in audio and video so you can chat
                and also pick up on those tells when somebody is bluffing. Pefect for your home game in these times of isolation, or if distance is
                keeping you apart.</p><p>Just enter a room name (or use the random one suggested for you) and nick, and you are ready to go!</p>
                <b>No registration needed!</b>
                </div>
                <div id="joingame">
                <div className="joincont">
                    <div className="jointitle">Start or join a game</div>
                    <div className="roomselect">
                        <form>
                            <input className="roominput" type="text" placeholder={this.state.roomPlaceholder} value={this.state.room} onChange={this.handleChange} />
                            <input className="nickinput" type="text" autoFocus={true} value={this.state.nick} placeholder="Your name" onChange={this.handleNickChange} />
                        </form>
                    </div>
                </div>
                <div className="joinbtn" onClick={this.join}>GO!</div>

                </div>
            </div>
        )
    }
}

export default Welcome
