import React, { useState, useRef, useEffect } from 'react'
import {Settings} from './Settings'
import AdmSettings from './AdmSettings'
import {useToasts} from 'react-toast-notifications'

function SideMenu(props) {
    const { addToast } = useToasts()
    const setref = useRef();

    const [sethide, setSethide] = useState(" hidden")
    const [admhide, setAdmhide] = useState(" hidden")

    const handleClickOutside = e => {
        if (setref.current && setref.current.contains(e.target)) {
            // inside click
            return;
        }
        // outside click
        setSethide(' hidden')
        setAdmhide(' hidden')
    };

    useEffect(() => {
        if (!sethide || !admhide) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [sethide, admhide]);

    const setclick = () => {
        if (sethide) setSethide('')
        else setSethide(' hidden')
        setAdmhide(' hidden')
    }

    const admclick = () => {
        setSethide(' hidden')
        if (admhide) setAdmhide('')
        else setAdmhide(' hidden')
    }

    const invite = () => {
        navigator.clipboard.writeText(props.inviteurl).then(function () {
            addToast("Invite url copied to clipboard, please paste and share with your friends!", { appearance: 'success' })
        }, function () {
            addToast("Unable to copy to clipboard, please copy  " + props.inviteurl + " manually and share with your friends!", { appearance: 'error' })
        });
    }

    const settingsHandler = (settings, close) => {
        if (close) {
            setSethide(' hidden')
        }
        props.setSettings(settings)
    }

    const admSettingsHandler = (settings, close) => {
        if (close) {
            setAdmhide(' hidden')
        }
        props.setAdmSettings(settings)
    }

    const setjitsi = () => {
        setSethide(' hidden')
        setAdmhide(' hidden')
        props.setSettings({...props.settings, jitsi: !props.settings.jitsi})
    }

    return (
        <div ref={setref} className="sidemenu">
            <div className={"setcont" + sethide}>
                <div className="speech-bubble">
                    <Settings settings={props.settings} setSettings={settingsHandler} />
                </div>
            </div>
            <div className={"admcont" + admhide}>
                <div className="speech-bubble">
                    <AdmSettings settings={props.admsettings} setAdmSettings={admSettingsHandler} isadmin={props.isadmin}/>
                </div>
            </div>
            <img src="/imgs/video.svg" alt="Video" className={props.settings.jitsi ? "enabled" : ""} onClick={setjitsi} />
            <img src="/imgs/settings.svg" alt="Settings" onClick={setclick} />
            <img src="/imgs/admin.svg" alt="Admin settings" onClick={admclick} />
            <img src="/imgs/invite.svg" alt="Invite" onClick={invite} />
        </div>
    )

}

export default SideMenu
