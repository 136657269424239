import React from 'react'

function Card(props) {
  let dimclass = props.dimmed ? ' dimmed' : ''
  if (props.hidden) {
    dimclass += ' hidden'
  }
  if (props.flippable) {
    return (
      <div className={"flip-card" + (props.flipped ? " flipped": "") + dimclass}>
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img src="/imgs/cards/hh.png" alt="Avatar" />
          </div>
          <div className="flip-card-back">
            <img src={`/imgs/cards/${props.code}.png`} alt="Avatar" />

            </div>
          </div>
        </div>
    )
  } else {
    return(
      <img
        src={`/imgs/cards/${props.code}.png`}
        alt="card"
        className={"card" + dimclass}
        onClick={props.handleClick}
      />
    )
  }
}

export default Card
