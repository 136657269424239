import React from 'react'


function CheckboxButton(props) {

    //const [checked, setChecked] = useState(false);

    var cname = "actncheck"
    if (!props.showcheck || props.hidden) {
        cname += " hidden"
    }
    return (
        <div className={props.hidden ? "actnbtn hidden" : "actnbtn"} onClick={props.showcheck ? null : props.onClick}>
            <input
                className={cname}
                type="checkbox"
                checked={props.checked}
                onChange={props.onClick} />

            {props.title}
        </div>
    )

}

export default CheckboxButton
