import React, { useState } from 'react'
import { Checkbox, ApplyCancelButtons, SettingsTextInput } from './Settings'
import { useToasts } from 'react-toast-notifications'

function AdmSettings(props) {
    const { addToast } = useToasts()

    const [bigblind, setBigblind] = useState(props.settings.blinds.bigBlind)
    const [autobuyin, setAutobuyin] = useState(props.settings.autobuyin)


    const onclick = (source) => {
        if (!props.isadmin) {
            // Not admin, not allowed to change..
            addToast("Only admins allowed to change game settings!", { appearance: 'error' })
            return;
        }
        let newsettings = { ...props.settings }
        newsettings[source] = !newsettings[source]

        props.setAdmSettings(newsettings, false)
    }
    const onapply = () => {
        let bb = parseInt(bigblind)
        let ab = parseInt(autobuyin)
        if (isNaN(bb) || isNaN(ab)) {
            oncancel()
            return
        }
        props.setAdmSettings({ ...props.settings, blinds: {bigBlind: bb, smallBlind: bb/2}, autobuyin: ab }, true)
    }
    const oncancel = () => {
        setBigblind(props.settings.blinds.bigBlind)
        setAutobuyin(props.settings.autobuyin)
    }
    if (props.settings.blinds.bigBlind !== parseInt(bigblind) || props.settings.autobuyin !== parseInt(autobuyin)) {
        var applycancel = <ApplyCancelButtons onapply={onapply} oncancel={oncancel} />
    }

    return (
        <div>
            <h3>Admin settings</h3>
            <Checkbox title="Approve before joining" checked={props.settings.approvejoin} onclick={onclick} source="approvejoin" />
            <Checkbox title="Allow observers" checked={props.settings.allowobservers} onclick={onclick} source="allowobservers" />
            <SettingsTextInput title="Big blind" value={bigblind} onchange={setBigblind} readonly={!props.isadmin} />
            <SettingsTextInput title="Auto buy-in new players" value={autobuyin} onchange={setAutobuyin} readonly={!props.isadmin} />
            {applycancel}
        </div>
)

}

export default AdmSettings
