import React, { useState } from 'react'

const Checkbox = ({ title, checked, onclick, source}) => {
    return (
        <div className="settings">
            <label>{title}</label>
            <input
                className="csett"
                type="checkbox"
                checked={checked}
                onChange={() => {onclick(source)}} />
        </div>
    )
}

const SettingsTextInput = ({ title, value, onchange, readonly }) => {
    return (
        <div className="settings">
            <label>{title}</label>
            <input
                className="tsett"
                type="input"
                value={value}
                onChange={(evt) => {onchange(evt.target.value)}}
                readOnly={readonly} />
        </div>
    )
}

const ApplyCancelButtons = ({ onapply, oncancel }) => {
    return (
        <div className="applycancel">
            <div className="actnbtn applybtn" onClick={onapply}>Apply</div>
            <div className="actnbtn cancelbtn" onClick={oncancel}>Cancel</div>
        </div>
    )
}

function Settings(props) {
    const [autotimeout, setAutotimeout] = useState(props.settings.autodealtimeout)

    const onclick = (source) => {
        let newsettings = {...props.settings}
        newsettings[source] = !newsettings[source]
        props.setSettings(newsettings, false)
    }
    const onapply = () => {
        let val = parseInt(autotimeout)
        if (isNaN(val)) {
            oncancel()
            return
        }
        if (val === 0) {
            val = 1
            setAutotimeout(val)
        }
        props.setSettings({ ...props.settings, autodealtimeout : val }, true)
    }
    const oncancel = () => {
        setAutotimeout(props.settings.autodealtimeout)
    }

    if (props.settings.autodealtimeout !== parseInt(autotimeout)) {
        var applycancel = <ApplyCancelButtons onapply={onapply} oncancel={oncancel} />
    }

    return (
        <div>
        <h3>Settings</h3>
        <Checkbox title="Enable Jitsi" checked={props.settings.jitsi} onclick={onclick} source="jitsi" />
        <Checkbox title="Enable sound" checked={props.settings.soundon} onclick={onclick} source="soundon" />
        <Checkbox title="Autodeal" checked={props.settings.autodeal} onclick={onclick} source="autodeal" />
        <SettingsTextInput title="Autodeal timeout" value={autotimeout} onchange={setAutotimeout} />
        {applycancel}
        </div>
    )

}

export { Settings, Checkbox, ApplyCancelButtons, SettingsTextInput }
