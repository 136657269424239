import React, { Component } from 'react'
import TableContainer from './containers/TableContainer'
import { getWinningCards } from './helpers/pokerCalculator'
import cards from './helpers/cardData'
import PlayerDisplay from './components/PlayerDisplay'
import ActionDisplay from './components/ActionDisplay'
import Jitsi from './components/Jitsi'
import ModalCollection from './components/ModalCollection'
import SideMenu from './components/SideMenu'

class Game extends Component {

  constructor(props) {
    super(props)

    this.state = {
      cards: cards,
      // The above is an array of card objects
      gamestate: { players: [], board: [], pot: 0, button: 0, nextToAct: 0, blinds: {bigBlind:2} },
      inhand: false,
      isactive: false,
      mystate: "SIT_OUT",

      winners: { winningcards: [], descr: ''},
      ws: null,
      foldnext: false,
      settings: {jitsi: false, autodeal: false, soundon: true, autodealtimeout: 5},
      admsettings: { approvejoin: false, allowobservers: false, blinds: { bigBlind: 4, smallBlind: 2, ante: 0}, autobuyin: 0},
      countdown: 0,
      countdowntimer: null,
      showcards : 0,
      showtimer : null,
      audio: null,
      isadmin: false
    }
    this.modalcoll = React.createRef();
    this.modalAction = this.modalAction.bind(this);
    this.removePlayer = this.removePlayer.bind(this);
    this.url = "/sounds/ping.wav";
    this.state.audio = new Audio(this.url);

  }

  componentDidMount() {
      this.connect();
  }

  timeout = 250; // Initial timeout duration as a class variable
  neterrid = null;

  connect = () => {
    document.cookie = 'room=' + this.props.room + '; path=/'
    document.cookie = 'nick=' + this.props.nick + '; path=/'
    const protocol = window.location.protocol === 'http:' ? 'ws' : 'wss'
    const host = this.props.host === 'localhost:3000' ? 'localhost:8080' : this.props.host
    var ws = new WebSocket(protocol + '://' + host + '/socket')
    let that = this; // cache the this
    var connectInterval;

    // websocket onopen event listener
    ws.onopen = () => {
      console.log("connected ");

      this.setState({ ws: ws });
      that.timeout = 250; // reset timer to 250 on open of websocket connection

      clearTimeout(connectInterval); // clear Interval on on open of websocket connection
      if (that.neterrid) {
        this.props.removeToast(that.neterrid)
        this.props.addToast("Connected to server", { appearance: 'success' })
      }
      this.Refresh()
    };

    // websocket onclose event listener
    ws.onclose = e => {
      console.log(
        `Socket is closed. Reconnect will be attempted in ${Math.min(
          10000 / 1000,
          (that.timeout + that.timeout) / 1000
        )} second.`,
        e.reason
      );

      that.timeout = Math.min(10000, that.timeout + that.timeout); //increment retry interval
      connectInterval = setTimeout(this.check, that.timeout); //call check function after timeout
      if (that.neterrid) {
        this.props.removeToast(that.neterrid)
      }
      that.neterrid = this.props.addToast("Nework problem. Unable to connect to server, try to reconnect in " + that.timeout/1000 + " seconds", { appearance: 'error', autoDismiss: false })
    };

    // websocket onerror event listener
    ws.onerror = err => {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );

      ws.close();
    };

    ws.onmessage = evt => {
      // listen to data sent from the websocket server
      console.log(evt.data)
      const message = JSON.parse(evt.data)

      this.handleMessage(message)
    }
  };

  /**
   * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
   */
  check = () => {
    const { ws } = this.state;
    if (!ws || ws.readyState === WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
  };

  handleMessage = (message) => {
    if ("error" in message) {
      this.props.addToast(message.error, { appearance: 'error' })
    }
    if ("warning" in message) {
      this.props.addToast(message.warning, { appearance: 'warning' })
    }
    if ("success" in message) {
      this.props.addToast(message.success, { appearance: 'success' })
    }
    if ("info" in message) {
      this.props.addToast(message.info, { appearance: 'info' })
    }
    if ("state" in message) {
      this.handleStateChanges(message.state)
    }
      //console.log(message)
  }

  // Clears countdowntimer and sets new one
  updateCountdowntimer = (newgamestate) => {
    // Code for countdowntimer really...
    var currentpercent = 100;
    var interval = null
    if (this.state.countdowntimer) {
      clearInterval(this.state.countdowntimer)
    }
    if (newgamestate.state !== "SHOWDOWN") {
      // Countdown timer
      interval = setInterval(() => {
        if (currentpercent > 0) {
          currentpercent -= 5;
          this.setState({ countdown: currentpercent });
          if (currentpercent === 40 && this.state.settings.soundon) {
            var promise = this.state.audio.play()
            if (promise !== undefined) {
              promise.catch(error => {
                // Auto-play was prevented
                // Show a UI element to let the user manually start playback
                this.props.addToast("Autoplay of sound (reminder) not allowed, please enable in settings", { appearance: 'warning' })
                this.setState({settings: {...this.state.settings, soundon: false}})
              }).then(() => {
                // Auto-play started
                //this.props.addToast("But now ok?", { appearance: 'warning' })
              });
            }
          }
        } else {
          clearInterval(interval);
          this.setState({ countdown: currentpercent, countdowntimer: null });
          // Todo: Send timeout/fold to server?
        }
      }, 1000);
    } else {
      currentpercent = 0
    }
    this.setState({
      countdown: currentpercent,
      countdowntimer: interval,
    })
  }

  checkForShowdown = (newgamestate) => {
    if (newgamestate.state !== "SHOWDOWN") {
      if (this.state.showtimer) {
        clearInterval(this.state.showtimer)
        this.setState({showtimer: null})
      }
      // Clear state just in case
      this.setState({
        winners: { winningcards: [], descr: '' },
        showcards: newgamestate.board.length
      })
    } else {
      // Showdown, check if all cards already on table, if not animate
      if (this.state.gamestate.board.length < 5 && newgamestate.board.length === 5) {
        // Unnecessary? Doesn't hurt
        this.setState({ showcards: this.state.gamestate.board.length })
        var showint = setInterval(() => {
          if (this.state.showcards < 5)
            if (this.state.showcards === 0)
              this.setState({ showcards: 3 })
            else
              this.setState({ showcards: this.state.showcards + 1 })
          else {
            clearInterval(showint)
            if (this.state.settings.autodeal) {
              setTimeout(this.Deal, this.state.settings.autodealtimeout * 1000);
            }
            this.updateWinner(newgamestate)

          }
        }, 2000)
        this.setState({ showtimer: showint })
      } else {
        this.setState({ showcards: newgamestate.board.length })
        if (this.state.settings.autodeal) {
          setTimeout(this.Deal, this.state.settings.autodealtimeout * 1000);
        }
        this.updateWinner(newgamestate)
      }
    }
  }

  handleStateChanges = (newgamestate) => {
    // Check if we're the active
    var pidx = newgamestate.players.findIndex(player => player.nick === this.props.nick)
    var inhand = (pidx >= 0 && (newgamestate.players[pidx].state === "IN_HAND" || newgamestate.players[pidx].state === "ALL_IN"))
    // Todo: Remove second part to prevent disconnected players being controlled by everybody
    var isactive = (pidx === newgamestate.nextToAct) || (!newgamestate.players[newgamestate.nextToAct].connected)
    var isadmin = pidx >= 0 && newgamestate.players[pidx].admin
    var mystate = pidx >= 0 ?  newgamestate.players[pidx].state : "OBSERVING"

    this.updateCountdowntimer(newgamestate)
    this.checkForShowdown(newgamestate)


    this.setState({
      gamestate: newgamestate,
      isactive: isactive,
      inhand: inhand,
      isadmin: isadmin,
      mystate: mystate
    })

    // Trigger automated actions
    if (isactive && this.state.foldnext) {
      //this.SendCmd("fold")
    }
  }

  updateWinner = (newgamestate) => {
    // Get all hands in pot
    if (newgamestate.board.length < 5) {
      return
    }
    let hands = newgamestate.players.filter(p => (p.state === "IN_HAND" || p.state === "ALL_IN")).map(function(p) {return p.hand})

    if (hands.length === 1) {
      return
    }

    // Find cards included in winning hands (could be more if tie)
    let wc = getWinningCards(hands, newgamestate.board)

    this.setState({
      winners: wc
    })
  }

  SendCmd = (cmd, payload) => {
    let jcmd = {type: cmd, payload: payload || {}}
    this.state.ws.send(JSON.stringify(jcmd))
  }

  Deal = () => {
    console.log("Deal!")
    this.SendCmd('deal')
  }

  Fold = (evt) => {
    evt.stopPropagation();
    this.SendCmd('fold')
    //this.props.addToast("Only FOOLS fold! Be a better man!", { appearance: 'warning' })
  }

  Checkcall = () => {
    this.SendCmd('call')
  }

  Raise = (val) => {
    let p = this.state.gamestate.players[this.state.gamestate.nextToAct]
    this.SendCmd('bet', {amount: val - p.bet} )
  }

  Refresh = () => {
    this.SendCmd('refresh')
  }

  Shuffle = () => {
    this.SendCmd('shuffle')
  }

  Leave = () => {
    if (this.state.mystate === "SIT_OUT")
      this.SendCmd('leave')
    else
      this.SendCmd('sitout')
  }

  Join = () => {
    this.SendCmd('join')
  }

  modalAction = (action) => {
    this.SendCmd(action.type, action.data)
  }

  AdmSettingsChanged = (newsettings) => {
    this.setState({ admsettings: newsettings })
    this.SendCmd("settings", newsettings)
  }

  SettingsChanged = (newsettings) => {
    console.log(newsettings);
    if (newsettings.soundon && !this.state.settings.soundon)
      this.state.audio.play();
    this.setState({ settings: newsettings })
  }

  foldnextChange = () => {
    this.setState({ foldnext: !this.state.foldnext})
  }

  removePlayer = (nick) => {
    // Todo: Proper confirm
    let r = window.confirm("Remove " + nick + " from game?")
    if (r) {
      this.SendCmd("remove", {nick: nick})
    }
  }

  render() {
    // Upper row
    var numplayers = this.state.gamestate.players.length
    var playerstop = []
    var playersbottom = []
    var numupper = Math.ceil((numplayers - 2) / 2)
    for (let i = 0; i < numupper; i++)
    {
      let player = this.state.gamestate.players[i]
      let align = "top"
      if (i < numupper/2) {
        align += " left"
      }
      playerstop.push(<PlayerDisplay
        key={i}
        player={player}
        oalign={align}
        countdown={this.state.countdown}
        button={this.state.gamestate.button === i ? 1 : 0}
        active={this.state.gamestate.nextToAct === i ? 1 : 0}
        winningCards={this.state.winners.winningcards}
        handleModal={this.modalcoll.current.showHandler}
        remove={this.removePlayer}
        isadmin={this.state.isadmin}
      />)
    }

    for (let j = numplayers - 2; j > numupper; j--) {
      let player = this.state.gamestate.players[j]
      let align = "bottom"
      if (j > ((numplayers - 2) - (numupper/2))) {
        align += " left"
      }
      playersbottom.push(<PlayerDisplay
        key={j}
        player={player}
        oalign={align}
        countdown={this.state.countdown}
        button={this.state.gamestate.button === j ? 1 : 0}
        active={this.state.gamestate.nextToAct === j ? 1 : 0}
        winningCards={this.state.winners.winningcards}
        handleModal={this.modalcoll.current.showHandler}
        remove={this.removePlayer}
        isadmin={this.state.isadmin}
      />)
    }
    let playerleft = ''
    if (numplayers) {
      playerleft =
        <PlayerDisplay
          key={numplayers - 1}
          player={this.state.gamestate.players[numplayers - 1]}
          oalign="left"
          countdown={this.state.countdown}
          button={this.state.gamestate.button === numplayers - 1 ? 1 : 0}
          active={this.state.gamestate.nextToAct === numplayers - 1 ? 1 : 0}
          winningCards={this.state.winners.winningcards}
          handleModal={this.modalcoll.current.showHandler}
          remove={this.removePlayer}
          isadmin={this.state.isadmin}
        />
    }
    let playerright = ''
    if (numplayers > 1) {
      playerright =
        <PlayerDisplay
          key={numupper}
          player={this.state.gamestate.players[numupper]}
          countdown={this.state.countdown}
          button={this.state.gamestate.button === numupper ? 1 : 0}
          active={this.state.gamestate.nextToAct === numupper ? 1 : 0}
          winningCards={this.state.winners.winningcards}
          handleModal={this.modalcoll.current.showHandler}
          remove={this.removePlayer}
          isadmin={this.state.isadmin}
      />
    }

    let bigblind = this.state.gamestate.blinds.bigBlind
    let minraise = 0
    let maxraise = 0
    let checkcall = "Check"
    if (numplayers > 1) {
      minraise = Math.max(this.state.gamestate.currentBet + this.state.gamestate.lastRaise, bigblind)
      maxraise = this.state.gamestate.players[this.state.gamestate.nextToAct].bet + this.state.gamestate.players[this.state.gamestate.nextToAct].stack
      if (minraise > maxraise && maxraise > this.state.gamestate.currentBet) {
        minraise = maxraise
      }
      if (this.state.gamestate.players[this.state.gamestate.nextToAct].bet < this.state.gamestate.currentBet) {
        checkcall = "Call"
      }
    }
    return (
      <div className="outer">
      { this.state.settings.jitsi ? (<Jitsi room={this.props.room} />) : null }
      <div className="game">
        <ModalCollection
          ref={this.modalcoll}
          players={this.state.gamestate.players}
          modalAction={this.modalAction}
        />

        <SideMenu
          settings={this.state.settings}
          admsettings={this.state.admsettings}
          setSettings={this.SettingsChanged}
          setAdmSettings={this.AdmSettingsChanged}
          isadmin={this.state.isadmin}
          inviteurl={"https://pokerdigs.com/" + this.props.room}
          />

        <div className="upper">
          {playerstop}
        </div>
        <div className="table-layout">
        {playerleft}
        <TableContainer
          name="Table"
          area="table"
          board={this.state.gamestate.board}
          dimmed={this.state.tabledimmed}
          pot={this.state.gamestate.pot}
          handdescr={this.state.winners.descr}
          winningCards={this.state.winners.winningcards}
          showcards={this.state.showcards}
        />
        {playerright}
        </div>
        <div>
        <div className="lower">
          {playersbottom}
        </div>
        </div>

        <ActionDisplay
          checkorcall={checkcall}
          pot={this.state.gamestate.pot}
          maxraise={maxraise}
          minraise={minraise}
          Deal={this.Deal}
          Refresh={this.Refresh}
          Fold={this.Fold}
          Checkcall={this.Checkcall}
          Raise={this.Raise}
          Shuffle={this.Shuffle}
          Leave={this.Leave}
          Join={this.Join}
          isactive={this.state.isactive}
          inround={this.state.gamestate.state && this.state.gamestate.state !== "SHOWDOWN"}
          state={this.state.mystate}
        />
      </div>
      </div>
    )
  }
}

export default Game
