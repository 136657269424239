import React, {useState} from 'react'
import Card from './Card'
import Stack from  './Stack'
import Countdown from './Countdown'

function PlayerDisplay(props) {

    const [showX, setShowX] = useState(false);
    const getstack = () => {
        switch (props.player.state) {
            case "SIT_OUT":
                return 'Sitting out'
            case "REMOVED":
                return 'Leaving'
            case "ALL_IN":
                return 'All-in'
            case "JOINING":
                return 'Joining'
            default:
                return props.player.stack
        }
    }

    const handleTip = () => {
        props.handleModal('tip', props.player.nick)
    }

    const handleBuyIn = () => {
        props.handleModal('buyin', props.player.nick)
    }

    const oddsClass = props.oalign === "left" ? 'playerodds left' : 'playerodds'
    let dealerbutton = ''
    if (props.button) {
        dealerbutton = <div className="dealerbutton">B</div>
    }
    let countdown = ''
    if (props.active)
    {
        countdown = <Countdown percent={props.countdown} />
    }

    const rmclass = showX && props.isadmin ? "rmplayer" : "rmplayer hidden"

    return (
        <div className={"playerdisplay "+ props.oalign}>
            <div className="playercards">
            {props.player.state !== "FOLDED" && props.player.state !== "SIT_OUT" ? props.player.hand.map((card, idx) => (
                <Card
                    key={card+idx+props.player.nick}
                    code={card}
                    dimmed={ props.winningCards.length ? (props.winningCards.includes(card) ? 0 : 1) : 0 }
                />
            )) : ''}
            </div>
            <div className={"playerdisplaybox" + (props.player.connected ? "": " disconnect")} onMouseEnter={() => setShowX(true)}
                onMouseLeave={() => setShowX(false)} >
                <div className="nick" onClick={handleTip} >{props.player.nick}</div>
                <div className={"stack" + (props.isadmin ? " pointer" : "")} onClick={props.isadmin ? handleBuyIn : null} >{getstack()}</div>
                <img className={"spider" + (props.player.connected ? " hidden" : "")}
                    src="/imgs/spider.svg"
                    alt="Disconnected"
                    />
                <img className={rmclass}
                    src="/imgs/close.svg"
                    alt="Remove player"
                    onClick={() => { props.remove(props.player.nick) }}
                />
            </div>
            <div className={oddsClass}>{props.player.odds}</div>
            <div className={"betstack "+ props.oalign}>
                <Stack
                    amount={props.player.bet}
                    />
            </div>
            {dealerbutton}
            {countdown}
        </div>
    )
}

export default PlayerDisplay
