import React from 'react'

const cointypes = [10000, 2500, 500, 100, 25, 5, 1]
const coinname = ["tenk", "twofk", "fiveh", "oneh", "tw5", "five", "one"]

const breakDownCoins = (amount) => {
    var result = []
    for (var i = 0; i < cointypes.length; i++) {
        var num = Math.floor(amount / cointypes[i]);
        for (var j = 0; j < num; j++)
        {
            result.push(coinname[i]);
        }
        amount = amount % cointypes[i];
    }
    return result;
}

class Stack extends React.Component {
    render() {
        var coins = breakDownCoins(this.props.amount)
        return (
            <div>
            <div className="stack">
            { coins.map((cval, key) => (
                < div className="coin-cont" key={key} style={{ zIndex: coins.length - key }}>
                    <div className={"coin " + cval + (key === 0 ? ' top' : '')}>l - l</div>
                </div>
            ))}
            </div>
            <div className="stackamount">
            {this.props.amount ? this.props.amount : ''}
            </div>
            </div>
        )
    }
}

export default Stack
