import React from 'react'
import Button from './Button';

class RaiseDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: 0 };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.state.value < this.props.minraise || this.state.value > this.props.maxraise || this.props.pot !== prevProps.pot) {
            if (this.props.minraise <= this.props.maxraise)
                this.setState({ value: this.props.minraise});
        }
    }

    render() {
        return (
            <div className={this.props.hidden ? "raise-container hidden" : "raise-container"}>
                {/* The button below calculates the odds for a query */}
                <div className="raisebuttonrow">
                <button className="valbutt" onClick={() => this.setState({ value: 6})}>
                    3BB
                </button>
                <button className="valbutt" onClick={() => this.setState({ value: this.props.pot/2 })}>
                    50%
                </button>
                <button className="valbutt" onClick={() => this.setState({ value: this.props.pot })}>
                    Pot
                </button>
                <button className="valbutt" onClick={() => this.setState({ value: this.props.maxraise })}>
                    Max
                </button>
                </div>
                <div className="amountrow">
                <div className="amount">
                            <input type="text" value={this.state.value} onChange={this.handleChange} />
                            <input type="range" min={this.props.minraise} max={this.props.maxraise}
                                 value={this.state.value} className="slider" onChange={this.handleChange} />
                </div>
                <div className="slider"></div>
                </div>
                <Button
                    onClick={() => {this.props.Raise(this.state.value)}}
                    title={"Raise:  " + (this.state.value === this.props.maxraise ? "All-In" : this.state.value)}
                />
            </div>
        )
    }
}

export default RaiseDisplay
