import React from 'react'

class Countdown extends React.Component {

    render() {
        return (
            <div className="countdownbox">
                <div className="countdownfill" style={{width: this.props.percent + "%"}}></div>
            </div>
            )
    }
}

export default Countdown
